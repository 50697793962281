import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { IScroll, IActive, NavProp, IMobileActive } from './header.interface';
import { Logo, MobileLogo } from '../../assets/image';
import Dropdown, { IItem } from '../dropdown/Dropdown';

const S = {
  Wrapper: styled.header<{ isScroll: boolean; mobileActive: boolean | undefined; hover: boolean }>`
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
    transition: all 0.25s ease-in-out;
    background: ${({ isScroll, hover }) =>
      isScroll || hover ? 'rgba(255,255,255,1)' : `rgba(255,255,255, 0)`};
    ${({ mobileActive }) => mobileActive && 'background:  rgba(255,255,255,1);'}
    box-shadow: ${({ isScroll }) => (isScroll ? '0px 1px 0px 0px rgba(221,221,221,1);' : 'none')};
    font-family: 'Gmarket Sans';
  }
  `,
  Header: styled.div`
    width: 100%;
    max-width: 1140px;
    margin: auto;
    transition: all 0.2s ease-in-out;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${({ theme }) => theme.mobile} {
      padding: 0 2rem;
      margin: 0;
    }
  `,
  Logo: styled.img`
    height: 2.1rem;
    width: auto;
    object-fit: cover;

    @media ${({ theme }) => theme.mobile} {
      height: 1.1rem;
    }
  `,
  NavW: styled.div`
    display: flex;

    @media ${({ theme }) => theme.mobile} {
      display: none;
    }
  `,
  Navigation: styled.div`
    max-width: 1024px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.5rem;
  `,
  NavigationItem: styled.div<{ isScroll: boolean }>`
    ${({ theme }) => `color: ${theme.black};`}
    margin: 0 1rem;
    cursor: pointer;
    font-size: 0.85rem;
    padding: 0 0.3rem 0.1rem 0.3rem;
    white-space: nowrap;

    &:hover {
      opacity: 0.85;
      text-decoration: underline;
    }
  `,
  MobileItem: styled.div<IScroll>`
    color: ${({ theme }) => theme.black};
    display: none;
    font-size: 1.4rem;

    @media ${({ theme }) => theme.mobile} {
      display: unset;
      z-index: 9;
    }
  `,
};

const Mobile = {
  Menu: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Icon: styled.div<{ isTeam: boolean; isScroll: boolean; mobileActive: boolean | undefined }>`
    color: ${({ isTeam }) => (isTeam ? '#fff' : '#000')};
    ${({ isScroll }) => isScroll && 'color: #000;'}
    ${({ mobileActive }) => mobileActive && 'color: #000;'}
  `,
};

const L = {
  Lang: styled.div`
    color: ${({ theme }) => theme.black};
    display: flex;
    padding: 1.2rem 0;
    font-size: 0.85rem;

    @media ${({ theme }) => theme.mobile} {
      display: none;
    }
  `,
  LangItem: styled.div<{ active: boolean; isTeam: boolean; isScroll: boolean }>`
    padding: 0 1rem;
    border-right: 1px solid #cecece;
    color: #cecece;
    ${({ active, theme }) => active && `color: ${theme.black};`}
    ${({ active, isTeam }) => active && isTeam && `color: #fff;`}
    ${({ isScroll, active, theme }) => active && isScroll && `color: ${theme.black};`}
    cursor: pointer;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border: none;
    }
  `,
};

const MobileMenu = {
  W: styled.div<IMobileActive>`
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    font-size: ${({ theme }) => theme.mobileFontSize};
    background: rgba(255, 255, 255);
    overflow: hidden;
    height: 0;

    ${({ active }) =>
      active !== undefined &&
      `
      ${
        active
          ? `animation: 0.2s mobileHeaderIn forwards;`
          : `animation: 0.4s mobileHeaderOut forwards`
      }
    `}
  `,
  List: styled.ul`
    padding: 0 1.2rem;
  `,
  Item: styled.li`
    padding: 1rem 0;
    color: ${({ theme }) => theme.black};
  `,
  ItemText: styled.span``,
  Lang: styled.div`
    color: ${({ theme }) => theme.black};
    display: flex;
    width: 100%;
    padding: 1.2rem;
  `,
  LangItem: styled.div<IActive>`
    padding: 0 1rem;
    border-right: 1px solid #cecece;
    color: #cecece;
    ${({ active, theme }) => active && `color: ${theme.black};`}

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border: none;
    }
  `,
};

const Header: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const location = useLocation();

  const [isScroll, setIsScroll] = useState(false);
  const [headerHover, setHeaderHover] = useState(false);

  const onHover = () => {
    setHeaderHover(true);
  };

  const onHoverOff = () => {
    setHeaderHover(false);
  };

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 0) {
      setIsScroll(true);
    }
    if (window.pageYOffset === 0) {
      setIsScroll(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('mousewheel', handleScroll);
    window.addEventListener('touchmove', handleScroll);
    return () => {
      window.removeEventListener('mousewheel', handleScroll);
      window.removeEventListener('touchmove', handleScroll);
    };
  }, [handleScroll]);

  const NavItems: NavProp[] = [
    {
      text: t('SKYClassIntroduce'),
      toMove: () => window.open('/team'),
    },
    {
      text: t('application'),
      toMove: () =>
        window.document
          .querySelector(isMobile ? '#m_p3' : '#p3')
          ?.scrollIntoView({ behavior: 'smooth' }),
    },
    {
      text: t('program'),
      toMove: () =>
        window.document
          .querySelector(isMobile ? '#m_p6' : '#p6')
          ?.scrollIntoView({ behavior: 'smooth' }),
    },
    {
      text: t('companyIntroduce'),
      toMove: () =>
        window.document
          .querySelector(isMobile ? '#m_p9' : '#p9')
          ?.scrollIntoView({ behavior: 'smooth' }),
    },
  ];

  const Langs = [
    { text: 'KOR', value: 'ko-KR' },
    { text: 'ENG', value: 'en-US' },
  ];

  const onChangeLang = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const [mobileActive, setMobileActive] = useState<boolean | undefined>();

  const onMobileOpen = () => {
    if (!mobileActive) {
      setMobileActive(true);
    }
    setMobileActive(!mobileActive);
  };

  const onMobileClose = () => {
    window.scrollTo(0, 0);
    if (mobileActive !== undefined) {
      setMobileActive(false);
    }
  };

  const SNSItems: IItem[] = [
    { name: '네이버톡톡', url: 'https://talk.naver.com/ct/w4p495?frm=pblog#nafullscreen' },
    { name: '카카오톡', url: 'https://open.kakao.com/o/s1yNMose' },
    { name: '학원찾기', url: ' http://com.skyclass.kr/course_parent' },
    { name: 'Blog', url: 'https://blog.naver.com/barunidea0880' },
    { name: 'Cafe', url: 'https://cafe.naver.com/daechiskyclass' },
  ];

  return (
    <S.Wrapper
      isScroll={isScroll}
      hover={headerHover}
      mobileActive={mobileActive}
      onMouseOver={onHover}
      onMouseOut={onHoverOff}
    >
      <S.Header>
        <Link to="/" onClick={onMobileClose}>
          <S.Logo src={isMobile ? MobileLogo : Logo} />
        </Link>

        <S.NavW>
          <S.Navigation>
            {NavItems.map((item) => {
              return (
                <S.NavigationItem isScroll={isScroll} key={item.text} onClick={item.toMove}>
                  {item.text}
                </S.NavigationItem>
              );
            })}
            <Dropdown title="SNS" items={SNSItems} />
          </S.Navigation>
        </S.NavW>
        <L.Lang>
          {Langs.map((v) => (
            <L.LangItem
              key={v.value}
              active={v.value === i18n.language}
              onClick={() => onChangeLang(v.value)}
              isTeam={location.pathname === '/team'}
              isScroll={isScroll}
            >
              {v.text}
            </L.LangItem>
          ))}
        </L.Lang>
        <S.MobileItem isScroll={isScroll}>
          <Mobile.Menu>
            <Mobile.Icon
              isTeam={location.pathname === '/team'}
              isScroll={isScroll}
              mobileActive={mobileActive}
            >
              <FontAwesomeIcon icon={faBars} onClick={onMobileOpen} />
            </Mobile.Icon>
          </Mobile.Menu>
        </S.MobileItem>
        {isMobile && (
          <MobileMenu.W
            active={mobileActive}
            onClick={() => {
              setMobileActive(false);
            }}
          >
            <MobileMenu.List>
              {NavItems.map((v) => (
                <MobileMenu.Item key={v.text}>
                  <MobileMenu.ItemText onClick={v.toMove}>{v.text}</MobileMenu.ItemText>
                </MobileMenu.Item>
              ))}
              {SNSItems.map((v) => (
                <a href={v.url} target="__blank" key={v.url}>
                  <MobileMenu.Item>{v.name}</MobileMenu.Item>
                </a>
              ))}
            </MobileMenu.List>
            <MobileMenu.Lang>
              {Langs.map((v) => (
                <MobileMenu.LangItem
                  key={v.value}
                  active={v.value === i18n.language}
                  onClick={() => onChangeLang(v.value)}
                >
                  {v.text}
                </MobileMenu.LangItem>
              ))}
            </MobileMenu.Lang>
          </MobileMenu.W>
        )}
      </S.Header>
    </S.Wrapper>
  );
};

export default Header;
