import styled from 'styled-components';
import { IMainMenu } from '../layout/ScreenLayout';

export const N = {
  Wrapper: styled.div`
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: 0.8s fadeIn forwards;
  `,
  Top: styled.div`
    padding: 10px;
    padding-bottom: 0;
    border-bottom: 1px solid #cecece;
  `,
  Title: styled.div<IMainMenu>`
    border-left: 4px solid
      ${({ theme, type }) => (type === 'parents' ? theme.ParentsGreen : theme.StudentBlue)};
    font-size: 22px;
    padding: 5px 10px;
    margin: 14px 0;
  `,
  SubTitle: styled.div<IMainMenu>`
    padding: 20px 10px 10px 10px;
    color: #cecece;

    &.active {
      color: ${({ theme, type }) =>
        type === 'parents' ? theme.ParentsLightGreen : theme.StudentLightBlue};
    }
  `,
  List: styled.ul`
    margin: 10px;
    border-left: 2px solid #ececec;
  `,
  Item: styled.li`
    padding: 10px 20px;
    font-size: 14px;
  `,
  FirstLine: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    color: #cecece;

    &.active {
      color: black;
    }
  `,
  Box: styled.div<IMainMenu>`
    border: 2px solid #e9e9e9;
    border-radius: 10px;
    padding: 1px 8px;
    padding-top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;

    &.active {
      border-color: ${({ theme, type }) =>
        type === 'parents' ? theme.ParentsLightGreen : theme.StudentLightBlue};
      background: rgba(0, 159, 160, 0.15);
      color: ${({ theme, type }) =>
        type === 'parents' ? theme.ParentsLightGreen : theme.StudentLightBlue};
    }
  `,
  Text: styled.div``,
  SecondLine: styled.div`
    color: #cecece;
    margin-left: 10px;
  `,
};
