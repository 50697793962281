import { css } from 'styled-components';

export default css`
  @keyframes fadeInAndMoveUp {
    0% {
      opacity: 0;
      transform: translateY(-15px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes twinkle {
    0% {
      opacity: 0.7;
    }
    45% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0.7;
    }
  }

  @keyframes zoomIn {
    0% {
      opacity: 0;
      transform: scale3d(0.4, 0.4, 0.4);
    }

    50% {
      opacity: 0.7;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes bannermove {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-3600px);
    }
  }

  @keyframes beacon {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes main {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;
