import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import animation from './animation';
import effect from './effect';
import fourth from './screen_effect/fourth';
import seven from './screen_effect/seven';
import sixth from './screen_effect/sixth';
import third from './screen_effect/third';

const GlobalStyle = createGlobalStyle`
    ${reset};

    *, *::before, *::after {
        box-sizing: border-box;
        font-family: 'Gmarket Sans';
    }
      
    a:link { color: black; text-decoration: none;}
    a:visited { color: black; text-decoration: none;}
      
    input {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: none;
    }

    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      }
     
    input:focus { outline: none;}
     
     /* IE10 이상에서 input box 에 추가된 지우기 버튼 제거 */
     input::-ms-clear { display: none; }

     #root {
            overflow-x:hidden;
      }

      ${animation}
      ${effect}
      ${third}
      ${fourth}
      ${sixth}
      ${seven}
`;

export default GlobalStyle;
