import React from 'react';
import { L } from '../style/linkQR.style';
import { IconHeader, IconQR } from '../../../assets/image/screen/icon';

const LinkQR: React.FC = () => {
  return (
    <L.Wrapper>
      <L.Top src={IconHeader} />
      <L.Box>
        <L.QR src={IconQR} />
        <L.TimeText>남은 시간</L.TimeText>
        <L.TimeNumber>12</L.TimeNumber>
        <L.Btn>
          <L.BtnText>새로 고침</L.BtnText>
        </L.Btn>
      </L.Box>
    </L.Wrapper>
  );
};

export default LinkQR;
