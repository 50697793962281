import React, { Dispatch, SetStateAction } from 'react';
import ScreenLayout from '../layout/ScreenLayout';
import { C, Stu } from '../style/screen.style';
import {
  IconHomework,
  IconSns,
  IconLearn,
  IconNotice,
  IconCourse,
  IconWorry,
  IconEdu,
  IconLocal,
  IconQna,
  IconShare,
  IconAttendance,
  IconBlueTooth,
} from '../../../assets/image/screen/icon';

interface IMainMenu {
  setActive: Dispatch<SetStateAction<string>>;
}

const MainMenu: React.FC<IMainMenu> = ({ setActive }) => {
  return (
    <ScreenLayout type="student">
      <C.MainTop type="student">
        <Stu.IconCol>
          <C.MainIcon onClick={() => setActive('link')}>
            <C.MIcon src={IconAttendance} />
            <C.IconText>출석</C.IconText>
          </C.MainIcon>
          <C.MainIcon onClick={() => setActive('beacon')}>
            <C.MIcon src={IconBlueTooth} />
            <C.IconText>비콘</C.IconText>
          </C.MainIcon>
        </Stu.IconCol>
        <Stu.IconCol>
          <C.MainIcon onClick={() => setActive('stamp')}>
            <C.MIcon src={IconLearn} />
            <C.IconText>학습</C.IconText>
          </C.MainIcon>
          <C.MainIcon onClick={() => setActive('notice')}>
            <C.MIcon src={IconNotice} />
            <C.IconText>공지사항</C.IconText>
          </C.MainIcon>
        </Stu.IconCol>
        <Stu.IconCol>
          <C.MainIcon onClick={() => setActive('homework')}>
            <C.MIcon src={IconHomework} />
            <C.IconText>숙제</C.IconText>
          </C.MainIcon>
          <C.MainIcon onClick={() => setActive('sns')}>
            <C.MIcon src={IconSns} />
            <C.IconText>SNS</C.IconText>
          </C.MainIcon>
        </Stu.IconCol>
      </C.MainTop>
      <C.MainBottom>
        <C.IconRow className="mainBottom">
          <C.MainIcon className="mainBottom">
            <C.MIcon src={IconCourse} />
            <C.IconText>진로상담</C.IconText>
            <C.IconText className="small">(학원찾기)</C.IconText>
          </C.MainIcon>
          <C.MainIcon className="mainBottom">
            <C.MIcon src={IconWorry} />
            <C.IconText>고민상담</C.IconText>
          </C.MainIcon>
          <C.MainIcon className="mainBottom">
            <C.MIcon src={IconShare} />
            <C.IconText>무료나눔</C.IconText>
          </C.MainIcon>
        </C.IconRow>
        <C.IconRow className="mainBottom">
          <C.MainIcon className="mainBottom">
            <C.MIcon src={IconEdu} />
            <C.IconText>교육소식</C.IconText>
          </C.MainIcon>
          <C.MainIcon className="mainBottom">
            <C.MIcon src={IconLocal} />
            <C.IconText>문화센터소식</C.IconText>
          </C.MainIcon>
          <C.MainIcon className="mainBottom">
            <C.MIcon src={IconQna} />
            <C.IconText>Q&A</C.IconText>
          </C.MainIcon>
        </C.IconRow>
      </C.MainBottom>
    </ScreenLayout>
  );
};

export default MainMenu;
