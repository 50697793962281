import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Card from '../../components/Card/Card';
import { Card1, Card2, Card3, Card4, Card5, Card6 } from '../../assets/team/web';

const T = {
  Wrapper: styled.article`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  `,
  Title: styled.div`
    width: 1140px;
    margin-top: 200px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${({ theme }) => theme.mobile} {
      width: 100%;
      padding: 0 30px;
    }
  `,
  TitleText: styled.div`
    font-size: 24px;
    opacity: 0.7;

    &.title {
      font-weight: bold;
      font-size: 62px;
      margin-bottom: 10px;
      opacity: 1;
    }

    &.end {
      margin-bottom: 30px;
    }

    @media ${({ theme }) => theme.mobile} {
      font-size: 16px;
      margin-bottom: 5px;

      &.title {
        font-size: 32px;
      }
    }
  `,
  CardLine: styled.div`
    display: flex;

    &.top {
      margin-top: 60px;
    }

    @media ${({ theme }) => theme.mobile} {
      flex-direction: column;
    }
  `,
};

const Cards: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <T.Wrapper>
      <T.Title>
        <T.TitleText className="title">SKYClass가 바꿀</T.TitleText>
        <T.TitleText className="title end">학원관리 시스템</T.TitleText>
        {isMobile ? (
          <>
            <T.TitleText>복잡한 학원관리,</T.TitleText>
            <T.TitleText>스카이클래스가 바꿔드립니다.</T.TitleText>
          </>
        ) : (
          <T.TitleText>복잡한 학원관리, 스카이클래스가 바꿔드립니다.</T.TitleText>
        )}
        <T.CardLine className="top">
          <Card
            title={['스카이클래스는', '항상 밝은 아이의 미래를', '생각합니다']}
            desc="스카이클래스는 우리 아이가 학원 교육을 어떻게 받아야 하는지 고민합니다.
            학부모님의 따스한 관심과 열정적인 학원장님들의 학원 교육을 온몸으로 체험하고
            학원 생활의 즐거움을 마음껏 자랑할 수 있는 시간이 우리 아이들에겐 필요합니다.
            스카이클래스는 우리 아이의 학업적 성취와 다양한 경험을 높이기 위해 학생, 학부모, 학원장님들이 함께하는 쾌적한 환경을 생각합니다."
            img={Card1}
          />
          <Card
            title={['스카이클래스는', '학원장님 입장에서', '고민하고 생각합니다']}
            desc="스카이클래스는 많은 학원장님들의 니즈를 반영하여 학원 운영에 꼭 필요한 서비스를 제공하고 편의성을 최대한 높인 프로그램으로 학원장님들과 강사님들의 업무 피로도를 줄이고 학부모님들께 우리 학원의 관리를 잘 보여줄 수 있습니다."
            img={Card2}
          />
        </T.CardLine>
        <T.CardLine>
          <Card
            title={['쉽고 편리한', '학원관리 서비스', '무료로 사용해보세요']}
            desc="QR, BLE, 키패드 등을 통한 편리한 출석 체크, 자녀 출결 History를 한눈에 확인, 직책에 따른 다른 관리자 권한 부여, 터치 한번으로 보는 숙제와 알림, 학생을 위한 커뮤니티, 원생 등록의 간편함, 시간대별 클래스인원 현황판, 개별 및 단체 알림 가능 등 학원 운영에 꼭 필요한 직관적이고 지속가능한 학원 관리 기능들을 무료로 사용하실 수 있습니다."
            img={Card3}
          />
          <Card
            title={['기존의 기능은 그대로', '자동화된 업무지원으로', '편리하게', '관리하세요']}
            desc="학원생 출결, 결제, 수업, 숙제관리, 매니저·강사 등 직책에 따른 다른 권한 부여, 원생 등록의 간편함, 시간대별 클래스 인원 현황판, 전화번호가 노출되지 않는 사생활 보호 기능, 개별 및 단체 알림 가능 등 이제껏 경험하지 못했던 쉽고 편리한 학원관리 서비스, 스카이클래스와 함께라면 학원운영이 새로워질거에요."
            img={Card4}
          />
        </T.CardLine>
        <T.CardLine>
          <Card
            title={['학습History Big Data를', '기반으로 하는', 'SKYClass만의', '매칭 시스템']}
            desc="자녀 학습의 지속적인 모니터링을 통해 가장 적절한 학원을 찾아줍니다. 이전의 선배들의 진로진학에 대한 데이터를 바탕으로 우리 자녀에게 가장 알맞은 학습의 길을 알려주며 진로고민에 대한 상담을 통해 힘들고 지친 우리 아이들에게 동기부여를 해주는 그리고 소통을 통한 안정을 취하는 소통형 커뮤니티 공동체를 만들어가고 있습니다."
            img={Card5}
          />
          <Card
            title={['학원 운영과 홍보의', '새로운 미래를', '제시합니다.']}
            desc="스카이클래스는 학원장님들과 함께 합니다. 다양한방법으로 바이럴 마케팅을 지원하며 오프라인 마케팅 지원합니다. 신입 원장님들을 위한 컨설팅 서비스, 지역별 서비스 담당자 지정으로 지속적인 학원 운영 관리를 함께합니다. 또한 현장에서 말하시는 학원장님들의 의견을 반영하여 스카이클래스의 미래를 함께 만들어가겠습니다."
            img={Card6}
          />
        </T.CardLine>
      </T.Title>
    </T.Wrapper>
  );
};

export default Cards;
