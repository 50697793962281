import React from 'react';
import { ScreenType } from '../layout/ScreenLayout';
import { N } from '../style/notice.style';

interface INotice {
  type: string;
  screenType: ScreenType;
}

const Notice: React.FC<INotice> = ({ type, screenType }) => {
  return (
    <N.Wrapper>
      <N.Top>
        <N.Title type={screenType}>{type === 'notice' ? '공지' : '숙제'}</N.Title>
      </N.Top>
      <N.SubTitle className="active" type={screenType}>
        12월 23일 (금)
      </N.SubTitle>
      <N.List>
        <N.Item>
          <N.FirstLine className="active">
            <N.Box className="active" type={screenType}>
              수학학원
            </N.Box>
            <N.Text>{type === 'notice' ? '전체공지' : '3단원 숙제'}</N.Text>
          </N.FirstLine>
          <N.SecondLine>홍길동 학생</N.SecondLine>
        </N.Item>
        <N.Item>
          <N.FirstLine className="active">
            <N.Box className="active" type={screenType}>
              수학학원
            </N.Box>
            <N.Text>{type === 'notice' ? '3학년 기초반 공지' : '3학년 기초문제'}</N.Text>
          </N.FirstLine>
          <N.SecondLine>홍길동 학생</N.SecondLine>
        </N.Item>
      </N.List>
      <N.SubTitle type={screenType}>12월 22일 (목)</N.SubTitle>
      <N.List>
        <N.Item>
          <N.FirstLine>
            <N.Box type={screenType}>수학학원</N.Box>
            <N.Text>{type === 'notice' ? '전체공지' : '3단원 숙제'}</N.Text>
          </N.FirstLine>
          <N.SecondLine>홍길동 학생</N.SecondLine>
        </N.Item>
        <N.Item>
          <N.FirstLine>
            <N.Box type={screenType}>수학학원</N.Box>
            <N.Text>{type === 'notice' ? '3학년 기초반 공지' : '3학년 기초문제'}</N.Text>
          </N.FirstLine>
          <N.SecondLine>홍길동 학생</N.SecondLine>
        </N.Item>
      </N.List>
    </N.Wrapper>
  );
};

export default Notice;
