import styled from 'styled-components';

interface IHover {
  hover: boolean;
  length?: number;
}

export const D = {
  Wrapper: styled.div<IHover>`
    position: relative;
  `,
  Box: styled.div<IHover>`
    position: absolute;
    overflow: hidden;
    height: 0;
    border-radius: 3px;
    background-color: #fff;
    transition: all 0.12s ease;

    ${({ hover, length }) =>
      hover &&
      `
        height: ${Number(length) * 30}px;
    `}
  `,
  Item: styled.div`
    ${({ theme }) => `color: ${theme.black};`}
    font-size: 0.85rem;
    padding: 0 0.3rem 0.1rem 0.3rem;
    margin: 0 1rem;
    cursor: pointer;
    white-space: nowrap;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Text: styled.div``,
};
