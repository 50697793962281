import React, { Dispatch, SetStateAction } from 'react';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { F } from './screenFooter.style';
import {
  IconTabAttendace,
  IconTabHomework,
  IconTabNotice,
  IconTabPay,
  IconTabSns,
  IconTabLink,
} from '../../../assets/image/screen/icon';
import { ScreenType } from './ScreenLayout';

interface IScreenFooter {
  setActive: Dispatch<SetStateAction<string>>;
  active: string;
  type: ScreenType;
}

const ScreenFooter: React.FC<IScreenFooter> = ({ setActive, active, type }) => {
  return (
    <F.Wrapper type={type}>
      <F.List>
        <F.Item>
          <F.IconDiv onClick={() => setActive('')}>
            <FontAwesomeIcon icon={faHouse} />
          </F.IconDiv>
          <F.Text>홈</F.Text>
        </F.Item>
        <F.Item className={active === 'stamp' ? 'active' : ''} onClick={() => setActive('stamp')}>
          <F.Icon src={IconTabAttendace} />
          <F.Text>등하원</F.Text>
        </F.Item>
        {type === 'parents' ? (
          <F.Item
            className={active === 'wallet' ? 'active' : ''}
            onClick={() => setActive('wallet')}
          >
            <F.Icon src={IconTabPay} />
            <F.Text>결제</F.Text>
          </F.Item>
        ) : (
          <F.Item className={active === 'link' ? 'active' : ''} onClick={() => setActive('link')}>
            <F.Icon src={IconTabLink} />
            <F.Text>학습</F.Text>
          </F.Item>
        )}
        <F.Item className={active === 'notice' ? 'active' : ''} onClick={() => setActive('notice')}>
          <F.Icon src={IconTabNotice} />
          <F.Text>공지</F.Text>
        </F.Item>
        <F.Item
          className={active === 'homework' ? 'active' : ''}
          onClick={() => setActive('homework')}
        >
          <F.Icon src={IconTabHomework} />
          <F.Text>숙제</F.Text>
        </F.Item>
        <F.Item className={active === 'sns' ? 'active' : ''} onClick={() => setActive('sns')}>
          <F.Icon src={IconTabSns} />
          <F.Text>SNS</F.Text>
        </F.Item>
      </F.List>
    </F.Wrapper>
  );
};

export default ScreenFooter;
