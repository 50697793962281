import React from 'react';
import { L } from '../style/linkQR.style';
import { B } from '../style/beacon.style';
import { IconHeader } from '../../../assets/image/screen/icon';

const Beacon: React.FC = () => {
  return (
    <L.Wrapper>
      <L.Top src={IconHeader} />
      <L.Box>
        <B.Title>스캔 중입니다.</B.Title>
        <B.Icon>
          <B.InnerIcon />
        </B.Icon>
        <L.TimeText>마지막 활성화</L.TimeText>
        <B.Text>0000-01-01 00:00</B.Text>
        <L.TimeText>최근 활성화</L.TimeText>
        <B.Text>2022-08-03 11:59</B.Text>
      </L.Box>
    </L.Wrapper>
  );
};

export default Beacon;
