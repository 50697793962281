import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { WebCountBack } from '../../../assets/web';
import { BackgroundImage } from './style/common.style';

const B = {
  W: styled.article`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 160px 0;
    width: 100%;
    overflow: hidden;
    position: relative;
  `,
  Img: styled(BackgroundImage)`
    background: url(${WebCountBack});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  `,
  TextW: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    line-height: 52px;
    margin-bottom: 100px;

    @media ${({ theme }) => theme.mobile} {
      font-size: 30px;
      line-height: 42px;
    }
  `,
  Text: styled.div``,
  List: styled.ul`
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${({ theme }) => theme.mobile} {
      flex-direction: column;
    }
  `,
  Item: styled.li`
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${({ theme }) => theme.mobile} {
      margin-bottom: 50px;
    }
  `,
  Desc: styled.div`
    opacity: 0.6;

    &.number {
      opacity: 1;
      font-size: 56px;
      font-weight: 700;
      margin-top: 40px;
      letter-spacing: 3px;
    }

    @media ${({ theme }) => theme.mobile} {
      &.number {
        font-size: 36px;
      }
    }
  `,
  Divider: styled.div`
    background-color: #ececec;
    width: 2px;
    height: 30px;
  `,
};

const Number: React.FC = () => {
  const { t } = useTranslation();
  return (
    <B.W>
      <B.TextW>
        <B.Text>{t('number_title_1')}</B.Text>
        <B.Text>{t('number_title_2')}</B.Text>
      </B.TextW>
      <B.List>
        <B.Item>
          <B.Desc>{t('number_desc_1')}</B.Desc>
          <B.Desc className="number">
            <CountUp start={0} end={41000} duration={1.5} suffix="+">
              {({ countUpRef, start }) => {
                return (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                );
              }}
            </CountUp>
          </B.Desc>
        </B.Item>
        <B.Item>
          <B.Desc>{t('number_desc_2')}</B.Desc>
          <B.Desc className="number">
            <CountUp start={0} end={5000} duration={1.3} suffix="+">
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
          </B.Desc>
        </B.Item>
        <B.Item>
          <B.Desc>{t('number_desc_3')}</B.Desc>
          <B.Desc className="number">
            <CountUp start={0} end={230} duration={1} suffix="+">
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
          </B.Desc>
        </B.Item>
      </B.List>
      <B.Img />
    </B.W>
  );
};

export default Number;
