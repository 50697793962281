import React from 'react';
import { W } from '../style/wallet.style';
import { IconAlert, IconCheck } from '../../../assets/image/screen/icon';

const Wallet: React.FC = () => {
  return (
    <W.Wrapper>
      <W.Title type="parents">결제</W.Title>
      <W.List>
        <W.Item className="active">
          <W.Icon src={IconAlert} />
          <W.TextBox>
            <W.Text>수학학원 | 홍길동 학생</W.Text>
            <W.Text className="red">누적 미납 금액 500,000원</W.Text>
          </W.TextBox>
        </W.Item>
        <W.Item>
          <W.Icon src={IconAlert} />
          <W.TextBox>
            <W.Text>국어학원 | 이영희 학생</W.Text>
            <W.Text className="red">누적 미납 금액 200,000원</W.Text>
          </W.TextBox>
        </W.Item>
        <W.Item>
          <W.Icon src={IconCheck} />
          <W.TextBox>
            <W.Text>국어학원 | 이영희 학생</W.Text>
            <W.Text>결제일까지 1일 남았어요</W.Text>
            <W.Text>금액 200,000원</W.Text>
          </W.TextBox>
        </W.Item>
        <W.Item>
          <W.Icon src={IconCheck} />
          <W.TextBox>
            <W.Text>국어학원 | 홍영희 학생</W.Text>
            <W.Text>결제일까지 1일 남았어요</W.Text>
            <W.Text>금액 200,000원</W.Text>
          </W.TextBox>
        </W.Item>
      </W.List>
    </W.Wrapper>
  );
};

export default Wallet;
