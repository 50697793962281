import styled from 'styled-components';

export const L = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.StudentLightBlue};
  `,
  Top: styled.img`
    width: 120px;
    height: auto;
    object-fit: contain;
    margin-bottom: 20px;
  `,
  Box: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 70%;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 60px;
  `,
  QR: styled.img`
    width: 70%;
    height: auto;
    object-fit: contain;
    margin-bottom: 20px;
  `,
  TimeText: styled.div`
    font-size: 12px;
    margin-bottom: 5px;
  `,
  TimeNumber: styled.div`
    font-size: 32px;
    color: ${({ theme }) => theme.StudentLightBlue};
    margin-bottom: 15px;
  `,
  Btn: styled.div`
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    user-select: none;
  `,
  BtnText: styled.div`
    font-size: 14px;
  `,
};
