import React, { useState } from 'react';
import styled from 'styled-components';
import { PlusBtn, CloseBlack } from '../../assets/team/web';

const C = {
  Wrapper: styled.div<{ src: string; active: boolean }>`
    width: 458px;
    height: 523px;
    border-radius: 15px;
    position: relative;

    background: url(${({ src }) => src});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    margin: 14px;
    ${({ active }) => !active && `cursor: pointer;`};

    @media ${({ theme }) => theme.mobile} {
      width: 100%;
      min-width: 320px;
      height: 420px;
      margin: 0;
      margin-bottom: 14px;
    }
  `,
  Title: styled.div<{ active: boolean }>`
    color: #fff;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    transition: 0.4s all;
    z-index: 2;

    ${({ active }) => active && `font-size: 22px; line-height: 24px; color: #000;`}

    @media ${({ theme }) => theme.mobile} {
      font-size: 22px;
      line-height: 26px;
      ${({ active }) => active && `font-size: 20px; line-height: 22px; color: #000;`}
    }
  `,
  Icon: styled.img`
    width: 30px;
    height: auto;
    object-fit: contain;
    margin-top: 30px;
    cursor: pointer;

    &.close {
      position: absolute;
      bottom: 40px;
      left: 24px;
    }
  `,
  Desc: styled.div`
    word-break: keep-all;
    margin-top: 40px;
    line-height: 22px;

    animation: 0.6s fadeIn ease forwards;

    @media ${({ theme }) => theme.mobile} {
      margin-top: 30px;
      font-size: 12px;
    }
  `,
  Blur: styled.div<{ active: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 24px;

    border-radius: 15px;
    transition: 0.05s all;

    ${({ active }) => active && `backdrop-filter: blur(50px); background:rgba(225, 239, 248, 0.8);`}
  `,
};

interface ICard {
  title: string[];
  desc: string;
  img: string;
}

const Card: React.FC<ICard> = ({ title, desc, img }) => {
  const [active, setActive] = useState(false);

  const onActive = () => {
    setActive(!active);
  };

  return (
    <C.Wrapper src={img} active={active} onClick={!active ? onActive : () => console.log('')}>
      <C.Blur active={active}>
        {title.map((v) => (
          <C.Title key={v} active={active}>
            {v}
          </C.Title>
        ))}
        {!active && <C.Icon src={PlusBtn} />}
        {active && <C.Desc>{desc}</C.Desc>}
        {active && <C.Icon className="close" src={CloseBlack} onClick={onActive} />}
      </C.Blur>
    </C.Wrapper>
  );
};

export default Card;
