import React from 'react';
import styled from 'styled-components';
import {
  Academy1,
  Academy2,
  Academy3,
  Academy4,
  Academy5,
  Academy6,
  Academy7,
  Academy8,
  Academy9,
  Academy10,
  Academy11,
  Academy12,
  Academy13,
  Academy14,
  Academy15,
  Academy16,
  Academy17,
  Academy18,
  Academy19,
  Academy20,
  Academy21,
  Academy22,
  Academy23,
  Academy24,
} from '../../../assets/web/academies';

const B = {
  W: styled.article`
    width: 100%;
    overflow: hidden;
    padding: 30px 0;
  `,
  W2: styled.div`
    display: flex;
    width: 2400px;
    animation: bannermove 30s linear infinite;

    @media ${({ theme }) => theme.mobile} {
      animation: bannermove 40s linear infinite;
    }
  `,
  Line: styled.div``,
  List: styled.div`
    display: flex;
    align-items: center;
  `,
  Item: styled.div`
    width: 100%;
    margin-right: 50px;
  `,
  Img: styled.img`
    width: 100px;
    height: auto;
    display: block;
  `,
};

const Academies: React.FC = () => {
  return (
    <B.W>
      <B.W2>
        <B.Line>
          <B.List>
            <B.Item>
              <B.Img src={Academy1} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy2} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy3} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy4} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy5} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy6} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy7} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy8} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy9} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy10} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy11} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy12} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy13} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy14} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy15} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy16} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy17} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy18} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy19} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy20} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy21} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy22} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy23} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy24} />
            </B.Item>
          </B.List>
        </B.Line>
        <B.Line>
          <B.List>
            <B.Item>
              <B.Img src={Academy1} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy2} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy3} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy4} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy5} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy6} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy7} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy8} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy9} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy10} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy11} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy12} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy13} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy14} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy15} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy16} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy17} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy18} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy19} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy20} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy21} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy22} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy23} />
            </B.Item>
            <B.Item>
              <B.Img src={Academy24} />
            </B.Item>
          </B.List>
        </B.Line>
      </B.W2>
    </B.W>
  );
};

export default Academies;
