import React, { useState } from 'react';
import { D } from './dropdown.style';

export interface IItem {
  name: string;
  url: string;
}

interface IDropdown {
  title: string;
  items: IItem[];
}

const Dropdown: React.FC<IDropdown> = ({ title, items }) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };

  const onHoverOff = () => {
    setHover(false);
  };

  return (
    <D.Wrapper hover={hover} onMouseOver={onHover} onMouseOut={onHoverOff}>
      <D.Item>
        <D.Text>{title}</D.Text>
      </D.Item>
      <D.Box hover={hover} length={items.length}>
        {items.map((v) => (
          <D.Item key={v.name}>
            <a href={v.url} target="__blank">
              <D.Text>{v.name}</D.Text>
            </a>
          </D.Item>
        ))}
      </D.Box>
    </D.Wrapper>
  );
};

export default Dropdown;
