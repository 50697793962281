import styled from 'styled-components';
import { IMainMenu } from '../layout/ScreenLayout';

export const C = {
  Wrapper: styled.div`
    width: 253px;
    height: 553px;
    margin-top: 15px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;

    @media ${({ theme }) => theme.mobile} {
    }
  `,
  Loading: styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 0;

    &.student {
      transform: scale(1.05);
    }
  `,
  BtnWrapper: styled.div`
    z-index: 1;
    position: relative;
    margin-top: 100%;
    height: 50px;
    width: 115px;
  `,
  Button: styled.div`
    width: 100%;
    height: 100%;
    font-size: 18px;
    color: #fff;
    background: rgba(43, 53, 68, 0.9);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;

    &:hover {
      transform: scale(1.01);
    }
  `,
  BtnBack: styled.div`
    position: absolute;
    top: -10px;
    left: -17px;
    width: 150px;
    height: 70px;
    background: #e0f5ff;
    opacity: 0.7;
    border-radius: 10px;
    animation: zoomIn 2s ease infinite;
    z-index: -1;
  `,
  InnerWrapper: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  `,
  Header: styled.div<IMainMenu>`
    width: 100%;
    height: 80px;
    background-color: ${({ type }) => (type === 'parents' ? '#009fa0' : '#466AD5')};
    display: flex;
    padding: 0 25px;
  `,
  HeaderIcon: styled.img`
    width: 100px;
    height: auto;
    object-fit: contain;
  `,
  Footer: styled.div<IMainMenu>`
    width: 100%;
    height: 77px;
    background-color: ${({ type, theme }) =>
      type === 'parents' ? theme.ParentsLightGreen : theme.StudentLightBlue};
  `,
  Main: styled.div`
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
    opacity: 0;
    animation: 0.8s fadeIn forwards;
  `,
  MainTop: styled.div<IMainMenu>`
    width: 100%;
    height: 50%;
    background-color: ${({ type, theme }) =>
      type === 'parents' ? theme.ParentsGreen : theme.StudentBlue};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0;

    ${({ type }) =>
      type === 'student' &&
      `
      flex-direction: row;
      padding: 0 14px;
    `}
  `,
  MainBottom: styled.div`
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
  `,
  IconRow: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;

    &.mainBottom {
      color: #000;
      opacity: 0.4;
    }
  `,
  MainIcon: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 70px;
    margin-bottom: 2px;
    cursor: pointer;
    user-select: none;

    &.mainBottom {
      cursor: unset;
    }

    &.student {
      height: 100px;
    }
  `,
  MIcon: styled.img`
    width: 30px;
    height: auto;
    object-fit: cover;
    margin-bottom: 10px;

    &.student {
      width: 60px;
    }
  `,
  IconText: styled.div`
    font-size: 10px;
  `,
};

export const Stu = {
  IconCol: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    width: 40px;
    height: 170px;
  `,
};
