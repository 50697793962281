import React from 'react';
import { S } from '../style/stamp.style';
import {
  IconAttendaceIn,
  IconAttendaceIn1,
  IconAttendaceOut,
  IconAttendaceOut1,
} from '../../../assets/image/screen/icon';
import { IMainMenu } from '../layout/ScreenLayout';

const Stamp: React.FC<IMainMenu> = ({ type }) => {
  return (
    <S.Wrapper>
      <S.MainTop type={type}>
        {type === 'parents' ? (
          <S.InnerBox>
            <S.TopIcon src={IconAttendaceIn} />
            <S.TextBox>
              <S.Text>이영희 학생이</S.Text>
              <S.Text>
                수학학원에 <S.Strong type={type}>현재 있습니다.</S.Strong>
              </S.Text>
            </S.TextBox>
          </S.InnerBox>
        ) : (
          <S.InnerBox>
            <S.TopIcon src={IconAttendaceOut} />
            <S.TextBox>
              <S.Text>이영희 학생이</S.Text>
              <S.Text>
                수학학원에 <S.Strong type={type}>있지 않습니다.</S.Strong>
              </S.Text>
            </S.TextBox>
          </S.InnerBox>
        )}
      </S.MainTop>
      <S.MainBottom>
        <S.Title>12월 23일(금) 등하원</S.Title>
        <S.List>
          <S.Item>
            <S.Icon src={IconAttendaceIn1} />
            <S.TextBox>
              <S.ItemText>이영희 학생이 수학학원 17시 30분</S.ItemText>
              <S.ItemText>등원하였습니다.</S.ItemText>
            </S.TextBox>
          </S.Item>
          <S.Item>
            <S.Icon src={IconAttendaceOut1} />
            <S.TextBox>
              <S.ItemText>이영희 학생이 수학학원 22시 30분</S.ItemText>
              <S.ItemText>하원하였습니다.</S.ItemText>
            </S.TextBox>
          </S.Item>
          <S.Item>
            <S.Icon src={IconAttendaceIn1} />
            <S.TextBox>
              <S.ItemText>이영희 학생이 수학학원 12시 30분</S.ItemText>
              <S.ItemText>등원하였습니다.</S.ItemText>
            </S.TextBox>
          </S.Item>
        </S.List>
      </S.MainBottom>
    </S.Wrapper>
  );
};

export default Stamp;
