import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import PageWrapper from '../../layout/PageWrapper';
import { useScroll } from '../../hooks/useScroll';
import Top from './web/Top';
import ParentsApp from './web/ParentsApp';
import Second from './web/Second';
import StudentApp from './web/StudentApp';
import Common from './web/Common';
import Admin from './web/Admin';
import MobileTop from './mobile/MobileTop';
import MobileSecond from './mobile/MobileSecond';
import MobilePApp from './mobile/MobilePApp';
import MobileSApp from './mobile/MobileSApp';
import MobileCommon from './mobile/MobileCommon';
import MobileAdmin from './mobile/MobileAdmin';
import Number from './web/Number';
import Floating from '../../components/floating/Floating';
import Academies from './web/Academies';
import Email from './mobile/Email';

const H = {
  Web: styled.div`
    display: unset;
    @media ${({ theme }) => theme.mobile} {
      display: none;
    }
  `,
  Mobile: styled.div`
    display: none;
    @media ${({ theme }) => theme.mobile} {
      display: unset;
    }
  `,
};

const Home: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [floatingActive, setFloatingActive] = useState(false);

  const { scrollY } = useScroll();

  const height = window.innerHeight - 35;

  useEffect(() => {
    setCurrentPage(Math.floor(scrollY / height));
    if (isMobile) {
      setFloatingActive(true);
    } else if (currentPage >= 1) {
      setFloatingActive(true);
    } else {
      setFloatingActive(false);
    }
  }, [scrollY]);

  const [emailActive, setEmailActive] = useState(false);

  const onEmailActive = () => {
    setEmailActive(!emailActive);
  };

  return (
    <PageWrapper>
      <H.Web>
        <Top onEmailActive={onEmailActive} />
        <Number />
        <Academies />
        <Second />
        <ParentsApp />
        <StudentApp />
        <Common />
        <Admin />
      </H.Web>
      <H.Mobile>
        <MobileTop />
        <Number />
        <Academies />
        <MobileSecond />
        <MobilePApp />
        <MobileSApp />
        <MobileCommon />
        <MobileAdmin />
      </H.Mobile>
      <Email active={emailActive} onEmailActive={onEmailActive} />
      <Floating active={floatingActive} onEmailActive={onEmailActive} />
    </PageWrapper>
  );
};

export default Home;
