const size = {
  mobile: '768px',
  desktop: '1460px',
};

const theme = {
  mainColor: '#f7a3c9',
  black: `rgba(0,0,0,0.75)`,
  mobileFontSize: `0.85rem`,
  mobile: `(max-width: ${size.mobile})`,
  desktop: `(max-width: ${size.desktop})`,
  background: `#F7F8F8`,
  ParentsGreen: '#314A4E',
  ParentsLightGreen: '#009fa0',
  StudentBlue: '#253464',
  StudentLightBlue: '#466AD5',
  green: '#00c3bb',
  blue: '#01a7fd',
};

export default theme;
