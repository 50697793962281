import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { WebSecondBack, WebMockUp1, WebMockUp2, WebMockUp3, WebMockUp4 } from '../../../assets/web';
import { BackgroundImage, PageWrapper } from './style/common.style';

const T = {
  Wrapper: styled(PageWrapper)`
    flex-direction: column;
  `,
  Img: styled(BackgroundImage)`
    background: url(${WebSecondBack});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  `,
  Box: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  `,
  TW: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 680px;
    margin-top: 60px;
    transform: translateY(60px);

    @media (max-width: 1700px) {
      width: 400px;
      margin-bottom: 60px;
      margin-top: 0;
    }
  `,
  TitleWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Title: styled.div`
    font-size: 18px;
    line-height: 30px;

    &.green {
      color: ${({ theme }) => theme.green};
    }

    &.blue {
      color: ${({ theme }) => theme.blue};
    }
  `,
  ImgWrapper: styled.div`
    &.first {
      margin-left: 100px;
    }
  `,
  MockUpImg: styled.img`
    width: 360px;
    height: auto;
    object-fit: contain;

    &.third {
      transform: translate(-190px, -80px);
    }

    &.web {
      width: 340px;
      transform: translateY(25px);
    }

    @media (max-width: 1700px) {
      width: 340px;

      &.third {
        transform: unset;
        margin-right: 140px;
      }

      &.web,
      &.first {
        display: none;
      }
    }
  `,
};

const Second: React.FC = () => {
  const { t } = useTranslation();

  return (
    <T.Wrapper id="p2">
      <T.TW>
        <T.TitleWrapper className="user">
          <T.Title>{t('home_second_left_1')}</T.Title>
          <T.Title>{t('home_second_left_2')}</T.Title>
          <T.Title className="green">{t('home_second_left_3')}</T.Title>
        </T.TitleWrapper>
        <T.TitleWrapper className="admin">
          <T.Title>{t('home_second_right_1')}</T.Title>
          <T.Title>{t('home_second_right_2')}</T.Title>
          <T.Title className="blue">{t('home_second_right_3')}</T.Title>
        </T.TitleWrapper>
      </T.TW>
      <T.Box>
        <T.ImgWrapper className="first">
          <T.MockUpImg src={WebMockUp1} className="first" />
          <T.MockUpImg src={WebMockUp2} />
        </T.ImgWrapper>
        <T.ImgWrapper className="second">
          <T.MockUpImg src={WebMockUp3} className="third" />
          <T.MockUpImg src={WebMockUp4} className="web" />
        </T.ImgWrapper>
      </T.Box>
      <T.Img />
    </T.Wrapper>
  );
};

export default Second;
