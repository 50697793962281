import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { init, send } from 'emailjs-com';
import { useForm } from 'react-hook-form';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const M = {
  Wrapper: styled.section<{ active: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;

    ${({ active }) => active && `display: flex`}
  `,
  I: styled.div`
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 720px;
    height: 480px;

    @media ${({ theme }) => theme.mobile} {
      width: 100%;
      height: 100%;
    }
  `,
  Box: styled.div`
    width: 100%;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  Label: styled.div`
    font-size: 0.9rem;
    color: #fff;
    margin-bottom: 8px;
  `,
  Input: styled.input`
    background: transparent;
    border-bottom: 2px solid white;
    margin-bottom: 20px;
    caret-color: #fff;
    color: #fff;
    font-size: 1.3rem;
    width: 100%;

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }

    &.phone {
      margin-bottom: 30px;
    }
  `,
  TextArea: styled.textarea`
    resize: none;
    width: 100%;
    font-size: 1.3rem;
    height: 100px;

    &:focus {
      outline: none;
    }
  `,
  Button: styled.div`
    border: 1px solid;
    border-color: rgba(255, 255, 255, 0.85);
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.85);
    background: rgba(255, 255, 255, 0.7);
    font-weight: bold;
    width: 140px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    align-self: center;
    margin-top: 40px;
    cursor: pointer;
    user-select: none;
  `,
  Icon: styled.div`
    position: absolute;
    top: 35px;
    right: 35px;
    color: #fff;
    font-size: 22px;
    cursor: pointer;
  `,
};

interface IForm {
  name: string;
  phone: string;
  academy_name: string;
}

interface IEmail {
  active: boolean;
  onEmailActive: () => void;
}

const Email: React.FC<IEmail> = ({ active, onEmailActive }) => {
  const { register, setValue, getValues } = useForm<IForm>({
    mode: 'onBlur',
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    init('8CD_Tp_8gcjE6mAqD');
  }, []);

  useEffect(() => {
    setValue('name', '');
    setValue('phone', '');
    setValue('academy_name', '');
  }, [active]);

  const onClick = async () => {
    const { name, phone, academy_name } = getValues();

    if (name === '' || phone === '' || academy_name === '') {
      alert('빈칸을 채워주세요');
      return;
    }

    if (!loading) {
      setLoading(true);
      await send(
        'service_jbm9bif',
        'template_0308sid',
        { name, phone, academy_name },
        '8CD_Tp_8gcjE6mAqD',
      );
    }
    alert('정상적으로 보냈습니다');
    setLoading(false);
    onEmailActive();
  };

  return (
    <M.Wrapper active={active}>
      <M.I>
        <M.Icon onClick={onEmailActive}>
          <FontAwesomeIcon icon={faX} />
        </M.Icon>
        <M.Box>
          <M.Label>Name</M.Label>
          <M.Input placeholder="홍길동" {...register('name')} />
          <M.Label>Phone</M.Label>
          <M.Input
            type="number"
            className="phone"
            placeholder="010-1234-5678"
            {...register('phone', { pattern: /^[0-9\b -]{0,13}$/ })}
          />
          <M.Label>Academy name</M.Label>
          <M.TextArea {...register('academy_name')} />
          <M.Button onClick={onClick}>Send</M.Button>
        </M.Box>
      </M.I>
    </M.Wrapper>
  );
};

export default Email;
