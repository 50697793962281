import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './style/global';
import theme from './style/theme';
import MainRouter from './router';

import './fonts.css';

const App: React.FC = () => {
  return (
    <Suspense fallback="loading">
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <MainRouter />
        </ThemeProvider>
      </HelmetProvider>
    </Suspense>
  );
};

export default App;
