import React from 'react';
import styled from 'styled-components';
import { Bg1 } from '../../assets/team/web';
import { BackgroundImage, PageWrapper } from '../Home/web/style/common.style';

const T = {
  Wrapper: styled(PageWrapper)`
    overflow: hidden;
  `,
  Img: styled(BackgroundImage)`
    background: url(${Bg1});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  `,
  Title: styled.div`
    width: 1140px;

    @media ${({ theme }) => theme.mobile} {
      width: 100%;
      padding: 0 30px;
    }
  `,
  TitleText: styled.div`
    font-weight: bold;
    font-size: 78px;
    margin-bottom: 10px;
    color: #fff;

    @media ${({ theme }) => theme.mobile} {
      font-size: 36px;
    }
  `,
};

const Top: React.FC = () => {
  return (
    <T.Wrapper>
      <T.Title>
        <T.TitleText>푸른 미래</T.TitleText>
        <T.TitleText>푸른 희망</T.TitleText>
        <T.TitleText>SKYClass가</T.TitleText>
        <T.TitleText>키워가겠습니다!</T.TitleText>
      </T.Title>
      <T.Img />
    </T.Wrapper>
  );
};

export default Top;
