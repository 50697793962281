import React from 'react';
import { C } from '../style/screen.style';
import { IconHeader } from '../../../assets/image/screen/icon';

export type ScreenType = 'parents' | 'student';

export interface IMainMenu {
  type: ScreenType;
}

const ScreenLayout: React.FC<IMainMenu> = ({ children, type }) => {
  return (
    <C.InnerWrapper>
      <C.Header type={type}>
        <C.HeaderIcon src={IconHeader} />
      </C.Header>
      <C.Main>{children}</C.Main>
      <C.Footer type={type} />
    </C.InnerWrapper>
  );
};

export default ScreenLayout;
