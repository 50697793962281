import styled from 'styled-components';

export const S = {
  Item: styled.li`
    border: 2px solid #e9e9e9;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  Left: styled.div``,
  Text: styled.div`
    font-size: 14px;
  `,
  Right: styled.div``,
  Img: styled.img`
    width: 28px;
    height: auto;
    object-fit: contain;
    margin-right: 5px;

    &:last-child {
      margin: 0;
    }
  `,
};
