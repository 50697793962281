import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  MobileCommonBack,
  WebCommon1,
  WebCommon2,
  MobileCommon3,
  MobileCommon4,
  WebCommon6,
} from '../../../assets/web';

const T = {
  Wrapper: styled.article`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  `,
  Img: styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background: url(${MobileCommonBack});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
  `,
  Title: styled.div`
    margin-top: 100px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  TitleText: styled.div`
    font-size: 16px;
    color: ${({ theme }) => `${theme.green}`};
    margin-bottom: 20px;

    &.desc {
      color: #000;
      font-size: 24px;
      margin-bottom: 40px;
    }
  `,
};

const List = {
  W: styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 420px;
    min-width: 340px;
    padding: 0 30px;
    margin-bottom: 200px;
  `,
  ItemLine: styled.li`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
  `,
  Item: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 400px;
  `,
  Img: styled.img`
    object-fit: contain;
    width: 110px;
    height: auto;
    margin-bottom: 10px;
  `,
  Title: styled.div`
    color: ${({ theme }) => `${theme.green}`};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 18px;
    font-size: 14px;

    position: absolute;
    top: 70px;

    &.one {
      top: 63px;
    }
  `,
  Desc: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    line-height: 20px;
    font-size: 12px;
    word-spacing: -2px;
    white-space: nowrap;
  `,
  Text: styled.div``,
};

const MobileCommon: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <T.Wrapper>
      <T.Title>
        <T.TitleText>{t('common_title')}</T.TitleText>
        <T.TitleText className="desc">{t('common_title_2')}</T.TitleText>
      </T.Title>
      <List.W>
        <List.ItemLine>
          <List.Item>
            <List.Img src={WebCommon1} />
            <List.Title className={i18n.language === 'en-US' ? 'one' : ''}>
              <List.Text>{t('common_1_title')}</List.Text>
              <List.Text>{t('common_1_title_2')}</List.Text>
            </List.Title>
            <List.Desc>
              <List.Text>{t('common_1_1')}</List.Text>
              <List.Text>{t('common_1_2')}</List.Text>
            </List.Desc>
          </List.Item>
          <List.Item>
            <List.Img src={WebCommon2} />
            <List.Title className={i18n.language === 'en-US' ? 'one' : ''}>
              <List.Text>{t('common_2_title')}</List.Text>
              <List.Text>{t('common_2_title_2')}</List.Text>
            </List.Title>
            <List.Desc>
              <List.Text>{t('common_2_1')}</List.Text>
              <List.Text>{t('common_2_2')}</List.Text>
            </List.Desc>
          </List.Item>
        </List.ItemLine>
        <List.ItemLine>
          <List.Item>
            <List.Img src={MobileCommon3} />
            <List.Title className={i18n.language === 'en-US' ? 'one' : ''}>
              <List.Text>{t('common_3_title')}</List.Text>
              <List.Text>{t('common_3_title_2')}</List.Text>
            </List.Title>
            <List.Desc>
              <List.Text>{t('common_3_1')}</List.Text>
              <List.Text>{t('common_3_2')}</List.Text>
            </List.Desc>
          </List.Item>
          <List.Item>
            <List.Img src={MobileCommon4} />
            <List.Title>
              <List.Text>{t('common_4_title')}</List.Text>
            </List.Title>
            <List.Desc>
              <List.Text>{t('common_4_1')}</List.Text>
              <List.Text>{t('common_4_2')}</List.Text>
            </List.Desc>
          </List.Item>
        </List.ItemLine>
        <List.ItemLine>
          {/* <List.Item>
            <List.Img src={WebCommon5} />
            <List.Title>
              <List.Text>{t('common_5_title')}</List.Text>
            </List.Title>
            <List.Desc>
              <List.Text>{t('common_5_1')}</List.Text>
              <List.Text>{t('common_5_mobile_2')}</List.Text>
              <List.Text>{t('common_5_mobile_3')}</List.Text>
            </List.Desc>
          </List.Item> */}
          <List.Item>
            <List.Img src={WebCommon6} />
            <List.Title>
              <List.Text>Q&A</List.Text>
            </List.Title>
            <List.Desc>
              <List.Text>{t('common_6_1')}</List.Text>
              <List.Text>{t('common_6_2')}</List.Text>
            </List.Desc>
          </List.Item>
          <List.Item />
        </List.ItemLine>
      </List.W>
      <T.Img />
    </T.Wrapper>
  );
};

export default MobileCommon;
