import React from "react";
import styled from "styled-components";
import {
  BlogF,
  CallF,
  MapF,
  InstaF,
  KakaoF,
  TistoryF,
  YoutubeF,
  TalkTalkF,
  LogoWhite,
} from "../../assets/image";

const F = {
  W: styled.footer`
    font-family: "Gmarket Sans";
    width: 100%;
    background-color: #283762;
  `,
  TextW: styled.div`
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 7.5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${({ theme }) => theme.mobile} {
      flex-direction: column;
      padding: 6rem 0;
    }
  `,
  Title: styled.div`
    color: #ececec;
    margin-bottom: 2rem;
    font-size: 0.9rem;
  `,
  Text: styled.div`
    color: #a0a0a0;
    font-size: 0.85rem;
  `,
  Line: styled.p`
    line-height: 1.4rem;
  `,
  Logo: styled.img`
    width: 9rem;
    height: auto;
    object-fit: cover;
    margin-right: 3rem;

    @media ${({ theme }) => theme.mobile} {
      margin-right: 0;
      width: 7.5rem;
      margin-top: 3rem;
    }
  `,
};

const List = {
  Wrapper: styled.ul`
    display: flex;
    margin-top: 10px;

    @media ${({ theme }) => theme.mobile} {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: 50%;
    }
  `,
  Item: styled.li`
    margin-right: 10px;
    margin-bottom: 5px;
    opacity: 0.9;
  `,
  Img: styled.img`
    @media ${({ theme }) => theme.mobile} {
      width: 32px;
      height: auto;
    }
  `,
  A: styled.a``,
};

const Icons = [
  { icon: CallF, href: "tel:010-6871-0770" },
  { icon: KakaoF, href: "https://open.kakao.com/o/s1yNMose" },
  {
    icon: TalkTalkF,
    href: "https://talk.naver.com/ct/w4p495?frm=pblog#nafullscreen",
  },
  {
    icon: MapF,
    href: "https://map.naver.com/v5/search/%ED%85%8C%ED%97%A4%EB%9E%80%EB%A1%9C%2044%EA%B8%B8%205/address/14142588.715333078,4509400.905501565,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EA%B0%95%EB%82%A8%EA%B5%AC%20%ED%85%8C%ED%97%A4%EB%9E%80%EB%A1%9C44%EA%B8%B8%205,new?c=14142569.3997716,4509404.4446484,19,0,0,0,dh&isCorrectAnswer=true",
  },
  { icon: BlogF, href: "https://blog.naver.com/barunidea0880" },
  /*  { icon: TistoryF, href: 'https://baia.tistory.com/' },
  { icon: InstaF, href: 'https://www.instagram.com/skyclass_0880/' },*/
  {
    icon: YoutubeF,
    href: "https://www.youtube.com/channel/UCa4fczjIUcFCC7UcTQdl71Q",
  },
];

const Footer: React.FC = () => {
  return (
    <F.W>
      <F.TextW>
        <div style={{ height: "220px" }}>
          <F.Title>주식회사 바른생각</F.Title>
          <F.Text>
            <F.Line>사업자 등록번호: 684-86-02358</F.Line>
            <F.Line>대표: 이주현</F.Line>
            <F.Line>경북 경산시 원효로 9, 4층 428호</F.Line>
            <F.Line>고객센터 : 053-267-0880 / 070-5176-9001</F.Line>
            <F.Line>help@skyclassism.com</F.Line>
            <List.Wrapper>
              {Icons.map((icon) => (
                <List.Item key={icon.href}>
                  <List.A href={icon.href} target="_blank">
                    <List.Img src={icon.icon} />
                  </List.A>
                </List.Item>
              ))}
            </List.Wrapper>
          </F.Text>
        </div>

        <div style={{ height: "220px" }}>
          <F.Title>주식회사 스카이클래스</F.Title>
          <F.Text>
            <F.Line>사업자 등록번호: 798-88-02520</F.Line>
            <F.Line>대표: 이주현</F.Line>
            <F.Line>대구 중구 동덕로 115, 진석타워즈 1115호</F.Line>
            <F.Line>고객센터 : 053-267-0880 /010-6871-0770</F.Line>
            <F.Line>전자지급결제대행업 등록번호 : 02-004-00209</F.Line>
            {/*            <List.Wrapper>
              {Icons.map((icon) => (
                  <List.Item key={icon.href}>
                    <List.A href={icon.href} target="_blank">
                      <List.Img src={icon.icon} />
                    </List.A>
                  </List.Item>
              ))}
            </List.Wrapper>*/}
          </F.Text>
        </div>
        <div style={{ height: "220px" }}>
          <F.Text>
            <F.Title>주식회사 스카이클래스/바른생각 공통 약관</F.Title>
            <a href={"https://bit.ly/3KOXCci"}>
              <F.Line style={{ marginTop: "10px", color: "#d4d4d4" }}>
                개인정보처리방침
              </F.Line>
            </a>
            <a href={"https://bit.ly/3xmziLE"}>
              <F.Line style={{ marginTop: "10px", color: "#d4d4d4" }}>
                서비스이용약관
              </F.Line>
            </a>
            <a href={"https://bit.ly/3KLikd9"}>
              <F.Line style={{ marginTop: "10px", color: "#d4d4d4" }}>
                위치기반서비스이용약관
              </F.Line>
            </a>
            <a
              href={
                "https://even-smile-8b5.notion.site/a045b9c213514980a9e1b558fa3cd00a?pvs=4"
              }
            >
              <F.Line style={{ marginTop: "10px", color: "#d4d4d4" }}>
                전자금융거래 이용약관
              </F.Line>
            </a>
          </F.Text>
        </div>
        <F.Logo src={LogoWhite} />
      </F.TextW>
    </F.W>
  );
};

export default Footer;
