import { css } from 'styled-components';
import {
  ScreenLoading,
  ScreenAppMenu1,
  ScreenParents1,
  ScreenParents2,
  ScreenParents3,
} from '../../assets/image/screen';

export default css`
  @keyframes SixthScreen {
    0% {
      background-image: url(${ScreenLoading});
    }
    15% {
      background-image: url(${ScreenLoading});
    }
    20% {
      background-image: url(${ScreenAppMenu1});
    }
    35% {
      background-image: url(${ScreenAppMenu1});
    }
    40% {
      background-image: url(${ScreenParents1});
    }
    55% {
      background-image: url(${ScreenParents1});
    }
    60% {
      background-image: url(${ScreenParents2});
    }
    75% {
      background-image: url(${ScreenParents2});
    }
    80% {
      background-image: url(${ScreenParents3});
    }
    95% {
      background-image: url(${ScreenParents3});
    }
    100% {
      background-image: url(${ScreenLoading});
    }
  }

  @keyframes SixthBox {
    0% {
      transform: translateX(100%);
    }
    8% {
      transform: translateX(80%);
    }
    20% {
      transform: translateX(80%);
    }
    28% {
      transform: translateX(60%);
    }
    40% {
      transform: translateX(60%);
    }
    48% {
      transform: translateX(40%);
    }
    60% {
      transform: translateX(40%);
    }
    68% {
      transform: translateX(20%);
    }
    80% {
      transform: translateX(20%);
    }
    88% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes SixthBoxReverse {
    0% {
      transform: translateX(-100%);
    }
    8% {
      transform: translateX(-80%);
    }
    20% {
      transform: translateX(-80%);
    }
    28% {
      transform: translateX(-60%);
    }
    40% {
      transform: translateX(-60%);
    }
    48% {
      transform: translateX(-40%);
    }
    60% {
      transform: translateX(-40%);
    }
    68% {
      transform: translateX(-20%);
    }
    80% {
      transform: translateX(-20%);
    }
    88% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0);
    }
  }
`;
