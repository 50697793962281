import React, { useState } from 'react';
import styled from 'styled-components';
import ScreenFooter from '../layout/ScreenFooter';
import MainMenu from './MainMenu';
import SNS from '../parentsScreen/SNS';
import Notice from '../parentsScreen/Notice';
import Stamp from '../parentsScreen/Stamp';
import LinkQR from './LinkQR';
import Beacon from './Beacon';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const Bridge: React.FC = () => {
  const [active, setActive] = useState('');
  return (
    <Wrapper>
      {active === '' && <MainMenu setActive={setActive} />}
      {active === 'stamp' && <Stamp type="student" />}
      {active === 'link' && <LinkQR />}
      {active === 'beacon' && <Beacon />}
      {active === 'notice' && <Notice type="notice" screenType="student" />}
      {active === 'homework' && <Notice type="homework" screenType="student" />}
      {active === 'sns' && <SNS type="student" />}
      {active !== '' && <ScreenFooter setActive={setActive} active={active} type="student" />}
    </Wrapper>
  );
};

export default Bridge;
