import React, { useEffect } from 'react';

const NotFound: React.FC = () => {
  useEffect(() => {
    window.location.href = '/';
  }, []);

  return <div />;
};

export default NotFound;
