import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PhoneFrame } from '../../../assets/image';
import { ScreenLoading } from '../../../assets/image/screen';
import {
  WebParentsAttendance,
  WebParentsSns,
  WebParentsGPS,
  WebParentsHomework,
  WebParentsPay,
  WebParentsNotice,
} from '../../../assets/web';
import ParentsAmule from '../../../components/Application/parentsScreen/ParentsApp';
import { P as A } from '../../../components/Application/style/phone.style';

const P = {
  Wrapper: styled.article`
    width: 100%;
  `,
  Inner: styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 0 25px;

    &.icons {
      padding-top: 80px;
      background-color: #e2f6f5;
    }
  `,
  ImageBox: styled.div`
    position: relative;
    height: 460px;
    width: 287px;
    transform: translateX(-20px);
  `,
  Phone: styled.img`
    position: absolute;
    height: 460px;
    width: auto;
  `,
  Screen: styled.div`
    position: absolute;
    height: 460px;
    width: 287px;
    background-size: contain;
    background-repeat: no-repeat;

    &.parents {
      background-image: url(${ScreenLoading});
      animation: 13s SixthScreen infinite;
    }
  `,
  Title: styled.div`
    font-size: 18px;
    color: ${({ theme }) => theme.green};
    margin-top: 60px;
    margin-bottom: 20px;

    &.none {
      opacity: 0.7;
      font-size: 14px;
      color: black;
      animation: twinkle 1.6s ease-in-out infinite;
      margin-bottom: 60px;
      margin-top: 0;
    }
  `,
  IconBox: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  IconLine: styled.div`
    width: 100%;
    max-width: 320px;
    display: flex;
    justify-content: space-between;
  `,
  Item: styled.div`
    width: 140px;
    margin-bottom: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  `,
  Icon: styled.img`
    width: 90px;
    height: auto;
    object-fit: contain;
  `,
  Desc: styled.div`
    line-height: 20px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    white-space: nowrap;

    &.title {
      margin-bottom: 10px;
      font-size: 14px;
      color: ${({ theme }) => theme.green};
    }
  `,
};

const MobilePApp: React.FC = () => {
  const { t } = useTranslation();

  return (
    <P.Wrapper id="m_p3">
      <P.Inner>
        <P.Title>{t('home_parents_title')}</P.Title>
        <P.Title className="none">{t('click')}</P.Title>
        <A.Screen>
          <ParentsAmule />
          <A.Frame src={PhoneFrame} />
        </A.Screen>
      </P.Inner>
      <P.Inner className="icons">
        <P.IconBox>
          <P.IconLine>
            <P.Item>
              <P.Icon src={WebParentsAttendance} />
            </P.Item>
            <P.Item>
              <P.Icon src={WebParentsPay} />
            </P.Item>
          </P.IconLine>
          <P.IconLine>
            <P.Item>
              <P.Icon src={WebParentsNotice} />
            </P.Item>
            <P.Item>
              <P.Icon src={WebParentsHomework} />
            </P.Item>
          </P.IconLine>
          <P.IconLine>
            <P.Item>
              <P.Icon src={WebParentsSns} />
            </P.Item>
            <P.Item>
              <P.Icon src={WebParentsGPS} />
            </P.Item>
          </P.IconLine>
        </P.IconBox>
      </P.Inner>
    </P.Wrapper>
  );
};

export default MobilePApp;
