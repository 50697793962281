import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Apple, Google, ManageBtn } from '../../../assets/image';
import { MobileMainBack, WebMain, WebMainS, WebMainK, WebMainY } from '../../../assets/web';
import { BackgroundImage, PageWrapper } from '../web/style/common.style';

const T = {
  Wrapper: styled(PageWrapper)`
    overflow: hidden;
  `,
  Img: styled(BackgroundImage)`
    width: 100vw;
    height: 100vh;
    background: url(${MobileMainBack});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  `,
  Box: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 25px;
    width: 100%;
    max-width: 360px;
    margin-top: 80px;
  `,
  Title: styled.div`
    width: 100%;
  `,
  TitleText: styled.div`
    font-size: 14px;
    margin-bottom: 10px;

    &.second {
      font-weight: bold;
      font-size: 36px;
      color: ${({ theme }) => `${theme.green}`};
      margin-bottom: 40px;
      margin-left: 0;
    }
  `,
  StoreWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 60px;
  `,
  StoreImg: styled.img`
    width: 100px;
    height: auto;
    cursor: pointer;

    transition: all 0.2s ease;

    &:hover {
      transform: scale(1.03);
    }
  `,
  RightImg: styled.img`
    width: 110%;
    height: auto;
    object-fit: contain;

    @media (min-width: 450px) {
      width: 130%;
    }
  `,
};

const RightImg = {
  W: styled.div`
    position: relative;
    width: 360px;
  `,
  Main: styled.img`
    width: 360px;
    height: auto;
    object-fit: contain;
  `,
  A: styled.img`
    width: 50px;
    height: auto;
    object-fit: contain;
    position: absolute;
    left: 45px;
    bottom: 150px;
    z-index: 3;
    animation: main 2.4s ease infinite;

    &.K {
      left: 80px;
      bottom: 170px;
      z-index: 2;
      animation-delay: 0.4s;
    }
    &.Y {
      left: 110px;
      bottom: 200px;
      z-index: 1;
      animation-delay: 0.2s;
    }
  `,
};

const MobileTop: React.FC = () => {
  const { t } = useTranslation();

  return (
    <T.Wrapper>
      <T.Box>
        <T.Title>
          <T.TitleText>{t('home_top_title_1')}</T.TitleText>
          <T.TitleText className="second">{t('home_top_title_2')}</T.TitleText>
        </T.Title>
        <T.StoreWrapper>
          <a href="https://apps.apple.com/app/id1569560496">
            <T.StoreImg src={Apple} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.nerdspace.hokma">
            <T.StoreImg src={Google} className="google" />
          </a>
          <a href="https://admin.skyclass.kr" target="__blank">
            <T.StoreImg src={ManageBtn} className="manage" />
          </a>
        </T.StoreWrapper>
        <RightImg.W>
          <RightImg.Main src={WebMain} />
          <RightImg.A src={WebMainS} />
          <RightImg.A src={WebMainK} className="K" />
          <RightImg.A src={WebMainY} className="Y" />
        </RightImg.W>
      </T.Box>
      <T.Img />
    </T.Wrapper>
  );
};

export default MobileTop;
