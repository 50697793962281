import styled from 'styled-components';

export const CenterBox = styled.div`
  width: 1140px;
`;

export const BackgroundImage = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
`;

export const PageWrapper = styled.article`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
