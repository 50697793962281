import styled from 'styled-components';

export const B = {
  Title: styled.div``,
  Icon: styled.div`
    width: 30px;
    height: 30px;
    border: 4px solid #ff9e9e;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    transform: scale(0);
    animation: beacon 1.4s ease infinite;
  `,
  InnerIcon: styled.div`
    width: 15px;
    height: 15px;
    border: 2px solid red;
    border-radius: 15px;
  `,
  Text: styled.div`
    color: ${({ theme }) => theme.StudentLightBlue};
    margin-bottom: 15px;
  `,
};
