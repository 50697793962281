import React from 'react';
import styled from 'styled-components';

const T = {
  Wrapper: styled.article`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #f5fafb;
  `,
  Title: styled.div`
    width: 1140px;
    margin: 180px 0;

    @media ${({ theme }) => theme.mobile} {
      width: 100%;
      padding: 0 30px;
    }
  `,
  TitleText: styled.div`
    font-size: 18px;
    line-height: 26px;

    &.title {
      font-weight: bold;
      font-size: 62px;
      line-height: 62px;
      margin-bottom: 30px;
    }

    @media ${({ theme }) => theme.mobile} {
      font-size: 16px;

      &.title {
        font-size: 36px;
        margin-bottom: 20px;
      }
    }
  `,
};

const Second: React.FC = () => {
  return (
    <T.Wrapper>
      <T.Title>
        <T.TitleText className="title">SKYClass는</T.TitleText>
        <T.TitleText>학원 관리 프로그램을 바탕으로 한</T.TitleText>
        <T.TitleText>학생들의 학습 관리의 효율성을 높이고</T.TitleText>
        <T.TitleText>학부모들의 자녀에 대한 안심을 통해</T.TitleText>
        <T.TitleText>교육의 새로운 패러다임을 열어가려 하는</T.TitleText>
        <T.TitleText>교육정보통합 플랫폼입니다.</T.TitleText>
      </T.Title>
    </T.Wrapper>
  );
};

export default Second;
