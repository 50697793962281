import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.main`
  font-family: 'Gmarket Sans';
`;

const PageWrapper: React.FC = ({ children }) => {
  const { i18n } = useTranslation();
  return (
    <Wrapper>
      <Helmet>
        <meta property="og:locale" content={i18n.language} />
      </Helmet>
      {children}
    </Wrapper>
  );
};

export default PageWrapper;
