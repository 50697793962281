import styled from 'styled-components';
import { IMainMenu } from './ScreenLayout';

export const F = {
  Wrapper: styled.div<IMainMenu>`
    position: absolute;
    top: calc(100% - 60px);
    left: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 28px;
    opacity: 0;
    animation: 0.6s fadeIn ease forwards;

    background-color: ${({ type, theme }) =>
      type === 'parents' ? theme.ParentsGreen : theme.StudentBlue};
  `,
  List: styled.ul`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 10px;
  `,
  Item: styled.li`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.4;
    user-select: none;

    &.active {
      opacity: 1;
    }
  `,
  Icon: styled.img`
    width: 22px;
    height: auto;
    object-fit: contain;
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(213deg) brightness(105%) contrast(101%);
    margin-bottom: 5px;
  `,
  IconDiv: styled.div`
    opacity: 0.9;
    filter: invert(100%);
    margin-bottom: 5px;
    font-size: 16px;
    margin-top: 4px;
  `,
  Text: styled.div`
    font-size: 10px;
    color: white;
  `,
};
