import styled from 'styled-components';

export const P = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 980px;
    height: 100%;
    margin: auto;
  `,
  Phone: styled.div`
    position: relative;
    @media ${({ theme }) => theme.mobile} {
      width: 100%;
      height: 100%;
    }
  `,
  Frame: styled.img`
    width: 424px;
    height: auto;
    object-fit: contain;
    position: absolute;
    top: -40px;
    left: -54px;
    z-index: 0;

    @media ${({ theme }) => theme.mobile} {
      top: -28px
      width: 100%;
    }
  `,
  Screen: styled.div`
    position: relative;
    width: 403px;
    height: 570px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;

    @media ${({ theme }) => theme.mobile} {
      margin-top: 0;
      margin-bottom: 100px;
    }
  `,
  Exchage: styled.div`
    background: rgba(43, 53, 68, 0.9);
    border-radius: 10px;
    padding: 0 10px;
    height: 35px;
    color: #fff;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    display: none;

    @media ${({ theme }) => theme.mobile} {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 10px;
      left: calc(100% - 115px);
    }
  `,
  ExchangeText: styled.div`
    margin-right: 10px;
  `,
  ExchangeIcon: styled.div``,
  Box: styled.div`
    width: 450px;
    height: 620px;

    @media ${({ theme }) => theme.mobile} {
      display: none;
    }
  `,
  Menu: styled.ul`
    display: flex;
  `,
  MenuItem: styled.li`
    width: 100px;
    height: 45px;
    font-size: 14px;
    color: #fff;
    background: rgba(43, 53, 68, 0.9);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    &:hover {
      transform: scale(1.02);
    }
    &.parents {
      margin-right: 30px;
    }
  `,
  ItemText: styled.span``,
  Title: styled.div`
    margin: 40px 0 60px 0;
    font-weight: bold;
    font-size: 38px;

    opacity: 0;
    animation: 1s fadeInAndMoveUp ease forwards;
  `,
  Desc: styled.div`
    line-height: 32px;
    font-size: 20px;

    opacity: 0;
    animation: 1s fadeInAndMoveUp ease 0.6s forwards;
  `,
};
