import React from 'react';
import PageWrapper from '../../layout/PageWrapper';
import Top from './Top';
import Second from './Second';
import Cards from './Cards';
import End from './End';

const Team: React.FC = () => {
  return (
    <PageWrapper>
      <Top />
      <Second />
      <Cards />
      <End />
    </PageWrapper>
  );
};

export default Team;
