import { css } from 'styled-components';
import { ScreenLogin, ScreenLogo } from '../../assets/image/screen';

export default css`
  @keyframes ThirdScreen {
    0% {
      background-image: url(${ScreenLogo});
    }
    10% {
      background-image: url(${ScreenLogin});
    }
    50% {
      background-image: url(${ScreenLogin});
    }
    60% {
      background-image: url(${ScreenLogo});
    }
    100% {
      background-image: url(${ScreenLogo});
    }
  }
`;
