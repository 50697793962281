import React, { useState } from 'react';
import { C } from '../style/screen.style';
import { P } from '../style/phone.style';
import { StudentLoading } from '../../../assets/image/screen';
import Bridge from './Bridge';

const StudentAmule: React.FC = () => {
  const [active, setActive] = useState(false);
  return (
    <C.Wrapper>
      {!active && (
        <>
          <C.Loading className="student" src={StudentLoading} />
          <C.BtnWrapper>
            <C.Button onClick={() => setActive(true)}>
              <P.ItemText>둘러보기</P.ItemText>
            </C.Button>
            <C.BtnBack />
          </C.BtnWrapper>
        </>
      )}
      {active && <Bridge />}
    </C.Wrapper>
  );
};

export default StudentAmule;
