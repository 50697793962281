import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CenterBox, PageWrapper } from './style/common.style';
import { PhoneFrame } from '../../../assets/image';
import { ScreenLoading2 } from '../../../assets/image/screen';
import {
  WebStudentAttendance,
  WebStudentStudy,
  WebStudentHomework,
  WebStudentNotice,
  WebStudentSns,
} from '../../../assets/web';
import StudentAmule from '../../../components/Application/studentScreen/StudnetsApp';
import { P as A } from '../../../components/Application/style/phone.style';

const P = {
  Wrapper: styled(PageWrapper)`
    background-color: #f6fbff;
  `,
  Box: styled(CenterBox)`
    width: 1200px;
    display: flex;
    justify-content: space-between;
  `,
  ImageBox: styled.div`
    position: relative;
    height: 720px;
    width: 500px;
    transform: translateX(-120px);
  `,
  Phone: styled.img`
    position: absolute;
    height: 720px;
    width: auto;
  `,
  Screen: styled.div`
    position: absolute;
    height: 720px;
    width: 468px;
    background-size: contain;
    background-repeat: no-repeat;

    &.parents {
      background-image: url(${ScreenLoading2});
      animation: 13s SevenScreen infinite;
    }
  `,
  Right: styled.div`
    height: 720px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 150px;
  `,
  Title: styled.div``,
  Text: styled.div`
    color: ${({ theme }) => theme.blue};
    font-size: 20px;
    margin-bottom: 8px;

    &.none {
      opacity: 0.7;
      margin: 0;
      font-size: 1rem;
      color: black;
      animation: twinkle 1.6s ease-in-out infinite;
    }
  `,
  IconBox: styled.div`
    height: 80%;
    width: 720px;
  `,
  IconLine: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  Item: styled.div`
    width: 220px;
    margin-bottom: 34px;
  `,
  Icon: styled.img`
    margin-bottom: 26px;
  `,
  Desc: styled.div`
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);

    &.title {
      margin-bottom: 10px;
      font-size: 18px;
      color: #000;
    }
  `,
};

const StudentApp: React.FC = () => {
  const { t } = useTranslation();
  return (
    <P.Wrapper>
      <P.Box>
        <A.Screen>
          <StudentAmule />
          <A.Frame src={PhoneFrame} />
        </A.Screen>
        <P.Right>
          <P.Title>
            <P.Text>{t('students_title')}</P.Text>
            <P.Text className="none">{t('click')}</P.Text>
          </P.Title>
          <P.IconBox>
            <P.IconLine>
              <P.Item>
                <P.Icon src={WebStudentAttendance} />
              </P.Item>
              <P.Item>
                <P.Icon src={WebStudentStudy} />
              </P.Item>
              <P.Item />
            </P.IconLine>
            <P.IconLine>
              <P.Item>
                <P.Icon src={WebStudentHomework} />
              </P.Item>
              <P.Item>
                <P.Icon src={WebStudentNotice} />
              </P.Item>
              <P.Item>
                <P.Icon src={WebStudentSns} />
              </P.Item>
            </P.IconLine>
          </P.IconBox>
        </P.Right>
      </P.Box>
    </P.Wrapper>
  );
};

export default StudentApp;
