import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Apple,
  Google,
  ManageBtn,
  InquiryBoxDesktop,
  KakaoIconDesktop,
  NaverIconDesktop,
  MailIconDesktop,
} from '../../../assets/image';
import { WebMainBack, WebMain, WebMainS, WebMainK, WebMainY } from '../../../assets/web';
import { BackgroundImage, CenterBox, PageWrapper } from './style/common.style';

const T = {
  Wrapper: styled(PageWrapper)`
    overflow: hidden;
  `,
  Img: styled(BackgroundImage)`
    background: url(${WebMainBack});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  `,
  Box: styled(CenterBox)`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Title: styled.div``,
  TitleText: styled.div`
    font-size: 20px;
    margin-bottom: 20px;
    margin-left: 10px;

    &.second {
      font-weight: bold;
      font-size: 78px;
      color: ${({ theme }) => `${theme.green}`};
      margin-bottom: 60px;
      margin-left: 0;
    }
  `,
  StoreWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  `,
  StoreImg: styled.img`
    width: 140px;
    height: auto;
    cursor: pointer;

    transition: all 0.2s ease;

    margin-right: 30px;

    &:hover {
      transform: scale(1.03);
    }

    &.manage {
      margin-right: 0;
    }
  `,
  RightImg: styled.img`
    object-fit: contain;
    margin-top: 220px;
  `,
};

const RightImg = {
  W: styled.div`
    position: relative;
  `,
  Main: styled.img`
    object-fit: contain;
    margin-top: 220px;
  `,
  A: styled.img`
    object-fit: contain;
    position: absolute;
    left: 145px;
    bottom: 380px;
    z-index: 3;
    animation: main 2.4s ease infinite;

    &.K {
      left: 220px;
      bottom: 415px;
      z-index: 2;
      animation-delay: 0.4s;
    }
    &.Y {
      left: 290px;
      bottom: 475px;
      z-index: 1;
      animation-delay: 0.2s;
    }
  `,
};

const I = {
  Box: styled.div`
    width: 100%;
    height: 150px;
    background-image: url('${InquiryBoxDesktop}');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Title: styled.div`
    letter-spacing: 3px;
    font-size: 26px;
    padding: 22px;
    margin-bottom: 5px;
  `,
  Text: styled.div`
    color: #fff;
  `,
  List: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 10px;
  `,
  Icon: styled.a`
    width: 135px;
    height: 52px;
    background-image: url('${MailIconDesktop}');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    padding-left: 52px;
    user-select: none;
    cursor: pointer;

    &.kakao {
      background-image: url('${KakaoIconDesktop}');
    }

    &.naver {
      background-image: url('${NaverIconDesktop}');
    }
  `,
  IconText: styled.div`
    font-size: 14px;
    text-align: center;
    width: 80px;
  `,
};

interface ITop {
  onEmailActive: () => void;
}

const Top: React.FC<ITop> = ({ onEmailActive }) => {
  const { t } = useTranslation();

  return (
    <T.Wrapper>
      <T.Box>
        <T.Title>
          <T.TitleText>{t('home_top_title_1')}</T.TitleText>
          <T.TitleText className="second">{t('home_top_title_2')}</T.TitleText>
          <T.StoreWrapper>
            <a href="https://apps.apple.com/app/id1569560496">
              <T.StoreImg src={Apple} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.nerdspace.hokma">
              <T.StoreImg src={Google} className="google" />
            </a>
            <a href="https://admin.skyclass.kr" target="__blank">
              <T.StoreImg src={ManageBtn} className="manage" />
            </a>
          </T.StoreWrapper>
          <I.Box>
            <I.Title>
              <I.Text>{t('freeRegister')}</I.Text>
            </I.Title>
            <I.List>
              <I.Icon onClick={onEmailActive}>
                <I.IconText>
                  <I.Text>{t('mail')}</I.Text>
                </I.IconText>
              </I.Icon>
              <I.Icon
                href="https://talk.naver.com/ct/w4p495?frm=pblog#nafullscreen"
                target="_blank"
                className="naver"
              >
                <I.IconText>
                  <I.Text>{t('naver')}</I.Text>
                </I.IconText>
              </I.Icon>
              <I.Icon href="https://open.kakao.com/o/s1yNMose" target="_blank" className="kakao">
                <I.IconText>
                  <I.Text>{t('kakao')}</I.Text>
                </I.IconText>
              </I.Icon>
            </I.List>
          </I.Box>
        </T.Title>
        <RightImg.W>
          <RightImg.Main src={WebMain} />
          <RightImg.A src={WebMainS} />
          <RightImg.A src={WebMainK} className="K" />
          <RightImg.A src={WebMainY} className="Y" />
        </RightImg.W>
      </T.Box>
      <T.Img />
    </T.Wrapper>
  );
};

export default Top;
