import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { Logo } from '../../assets/image';

const T = {
  Wrapper: styled.article`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #f5fafb;
  `,
  Title: styled.div`
    width: 1140px;
    margin: 140px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${({ theme }) => theme.mobile} {
      width: 100%;
      padding: 0 30px;
    }
  `,
  TitleText: styled.div`
    font-size: 48px;
    margin-bottom: 20px;

    &.end {
      margin-bottom: 40px;
    }

    @media ${({ theme }) => theme.mobile} {
      font-size: 26px;

      &.mobile {
        font-size: 32px;
        margin-bottom: 10px;
      }
    }
  `,
  Strong: styled.span`
    font-weight: bold;
  `,
  Logo: styled.img`
    height: 3rem;
    width: auto;
    object-fit: cover;
    margin-top: 30px;

    @media ${({ theme }) => theme.mobile} {
      height: 2rem;
    }
  `,
};

const End: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <T.Wrapper>
      <T.Title>
        <T.TitleText>
          <T.Strong>교육을</T.Strong> 생각하는
        </T.TitleText>
        <T.TitleText>
          <T.Strong>가치를</T.Strong> 연구하는
        </T.TitleText>
        <T.TitleText className="end">
          <T.Strong>미래를</T.Strong> 열어가는
        </T.TitleText>
        {isMobile ? (
          <>
            <T.TitleText className="mobile">
              <T.Strong>스카이클래스가</T.Strong>
            </T.TitleText>
            <T.TitleText className="mobile">
              <T.Strong>만들어 갑니다.</T.Strong>
            </T.TitleText>
          </>
        ) : (
          <T.TitleText>
            <T.Strong>스카이클래스가 만들어 갑니다.</T.Strong>
          </T.TitleText>
        )}

        <T.Logo src={Logo} />
      </T.Title>
    </T.Wrapper>
  );
};

export default End;
