import React from 'react';
import { faBars, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { G } from '../style/gps.style';
import { ScreenSwitch } from '../../../assets/image/screen';
import { IconGps } from '../../../assets/image/screen/icon';

const Gps: React.FC = () => {
  return (
    <G.Wrapper>
      <G.Top>
        <G.Left>
          <G.Bars>
            <FontAwesomeIcon icon={faBars} />
          </G.Bars>

          <G.TopText>자녀 목록</G.TopText>
        </G.Left>
        <G.Arrow>
          <FontAwesomeIcon icon={faAngleDown} />
        </G.Arrow>
      </G.Top>
      <G.Button>
        <G.BtnIcon src={IconGps} />
        <G.BtnText>학생 위치 조회</G.BtnText>
      </G.Button>
      <G.BackImg />
      <G.Switch src={ScreenSwitch} />
    </G.Wrapper>
  );
};

export default Gps;
