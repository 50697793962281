import { css } from 'styled-components';
import { ScreenAppMenu1, ScreenAppMenu2, ScreenAdminLogin } from '../../assets/image/screen';

export default css`
  @keyframes FourthScreen {
    0% {
      background-image: url(${ScreenAppMenu1});
    }
    25% {
      background-image: url(${ScreenAppMenu1});
    }
    30% {
      background-image: url(${ScreenAppMenu2});
    }
    60% {
      background-image: url(${ScreenAppMenu2});
    }
    65% {
      background-image: url(${ScreenAppMenu2});
    }
    70% {
      background-image: url(${ScreenAdminLogin});
    }
    95% {
      background-image: url(${ScreenAdminLogin});
    }
    100% {
      background-image: url(${ScreenAppMenu1});
    }
  }
`;
