import styled from 'styled-components';
import { IMainMenu } from '../layout/ScreenLayout';

export const S = {
  Wrapper: styled.div`
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: 0.6s fadeIn ease forwards;
  `,
  MainTop: styled.div<IMainMenu>`
    width: 100%;
    height: 40%;
    background-color: ${({ theme, type }) =>
      type === 'parents' ? theme.ParentsLightGreen : theme.StudentLightBlue};

    display: flex;
    align-items: center;
    justify-content: center;
  `,
  InnerBox: styled.div`
    background: #fff;
    border-radius: 10px;
    width: 90%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  `,
  TopIcon: styled.img`
    width: 70px;
    height: auto;
    object-fit: contain;
  `,
  TextBox: styled.div``,
  Text: styled.div`
    font-size: 13px;
    line-height: 18px;
  `,
  Strong: styled.span<IMainMenu>`
    color: ${({ type }) => (type === 'parents' ? 'blue' : 'red')};
  `,
  MainBottom: styled.div`
    width: 100%;
    height: 49%;
    z-index: 1;
    padding: 10px 16px;
    overflow-y: auto;
  `,
  Title: styled.div`
    height: 20px;
    font-size: 14px;
    line-height: 22px;
    border-left: 4px solid ${({ theme }) => theme.ParentsGreen};
    padding-left: 10px;
    margin-bottom: 20px;
  `,
  List: styled.ul`
    border-left: 1px solid #e9e9e9;
    padding-left: 10px;
    margin-left: 1px;
  `,
  Item: styled.li`
    display: flex;
    align-items: center;
    padding: 15px 0;
  `,
  Icon: styled.img`
    width: 30px;
    height: auto;
    obeject-fit: contain;
    margin-right: 6px;
  `,
  ItemText: styled.div`
    font-size: 11px;
    line-height: 16px;
  `,
};
