import styled from 'styled-components';
import { IMainMenu } from '../layout/ScreenLayout';

export const W = {
  Wrapper: styled.div`
    width: 100%;
    height: 100%;
    padding: 10px;
    opacity: 0;
    animation: 0.8s fadeIn forwards;
  `,
  Title: styled.div<IMainMenu>`
    border-left: 4px solid
      ${({ theme, type }) => (type === 'parents' ? theme.ParentsGreen : theme.StudentBlue)};
    font-size: 22px;
    padding: 5px 10px;
    margin: 14px 0;
  `,
  List: styled.ul``,
  Item: styled.li`
    border: 2px solid #e9e9e9;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 10px 15px;
    display: flex;
    align-items: center;

    &.active {
      border-color: ${({ theme }) => theme.ParentsLightGreen};
      background: rgba(0, 159, 160, 0.2);
    }
  `,
  Icon: styled.img`
    width: 18px;
    height: auto;
    object-fit: contain;
    margin-right: 10px;
  `,
  TextBox: styled.div``,
  Text: styled.div`
    font-size: 14px;
    line-height: 16px;
    &.red {
      color: red;
    }
  `,
};
