import React from 'react';
import { W } from '../style/wallet.style';
import {
  IconBlog,
  IconFacebook,
  IconInsta,
  IconKakao,
  IconSms,
} from '../../../assets/image/screen/icon';
import { S } from '../style/Sns.style';
import { ScreenType } from '../layout/ScreenLayout';

interface ISns {
  type: ScreenType;
}

const SNS: React.FC<ISns> = ({ type }) => {
  return (
    <W.Wrapper>
      <W.Title type={type}>SNS</W.Title>
      <W.List>
        <S.Item>
          <S.Left>
            <S.Text>OOO</S.Text>
            <S.Text>수학학원</S.Text>
          </S.Left>
          <S.Right>
            <S.Img src={IconKakao} />
            <S.Img src={IconSms} />
            <S.Img src={IconFacebook} />
          </S.Right>
        </S.Item>
        <S.Item>
          <S.Left>
            <S.Text>OOO</S.Text>
            <S.Text>영어학원</S.Text>
          </S.Left>
          <S.Right>
            <S.Img src={IconKakao} />
            <S.Img src={IconBlog} />
          </S.Right>
        </S.Item>
        <S.Item>
          <S.Left>
            <S.Text>AAA</S.Text>
            <S.Text>수학학원</S.Text>
          </S.Left>
          <S.Right>
            <S.Img src={IconKakao} />
            <S.Img src={IconInsta} />
            <S.Img src={IconFacebook} />
          </S.Right>
        </S.Item>
      </W.List>
    </W.Wrapper>
  );
};

export default SNS;
