import styled from 'styled-components';
import { ScreenMap } from '../../../assets/image/screen';

export const G = {
  Wrapper: styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    animation: 0.8s fadeIn forwards;
  `,
  BackImg: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: url('${ScreenMap}');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
  `,
  Top: styled.div`
    background-color: #fff;
    border: 1px solid gray;
    width: 75%;
    margin-top: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px;
    font-size: 14px;
    color: gray;
    border-radius: 20px;

    @media ${({ theme }) => theme.mobile} {
      padding: 12px 20px;
    }
  `,
  Left: styled.div`
    display: flex;
    align-items: center;
  `,
  Bars: styled.div`
    margin-right: 10px;
  `,
  TopText: styled.div`
    margin-top: 2px;
  `,
  Arrow: styled.div``,
  Button: styled.div`
    padding: 10px 20px;
    color: #fff;
    background: rgba(43, 53, 68, 0.9);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    margin-bottom: 100px;
  `,
  BtnIcon: styled.img`
    width: 20px;
    height: auto;
    object-fit: contain;
    margin-right: 10px;
  `,
  BtnText: styled.div`
    font-size: 14px;
    margin-top: 3px;
  `,
  Switch: styled.img`
    position: absolute;
    width: 34px;
    height: auto;
    object-fit: contain;
    top: calc(100% - 150px);
    right: 10px;
  `,
};
