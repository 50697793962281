import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MailIconMobile, KakaoIconMobile, NaverIconMobile } from '../../assets/image';

const F = {
  Box: styled.div<{ active: boolean }>`
    width: 80px;
    height: 290px;
    background-color: #3b4755;
    border-radius: 8px;
    position: fixed;
    top: 55%;
    right: 10%;
    display: none;
    flex-direction: column;
    align-items: center;
    z-index: 9;

    ${({ active }) => active && `display: flex;`}

    @media ${({ theme }) => theme.mobile} {
      right: 0;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      width: 60px;
      height: 255px;
    }
  `,
  Title: styled.div`
    padding: 10px 0;

    @media ${({ theme }) => theme.mobile} {
      font-size: 13px;
    }
  `,
  TextWrapper: styled.div`
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${({ theme }) => theme.mobile} {
      font-size: 0.8rem;
      line-height: 0.9rem;
    }
  `,
  Text: styled.div`
    color: #fff;
  `,
  List: styled.div``,
  Item: styled.a`
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    user-select: none;
    cursor: pointer;

    @media ${({ theme }) => theme.mobile} {
      font-size: 10px;
    }
  `,
  Icon: styled.img`
    width: 50px;
    height: auto;
    object-fit: contain;
    margin-bottom: 5px;

    @media ${({ theme }) => theme.mobile} {
      width: 40px;
    }
  `,
};

interface IFloating {
  active: boolean;
  onEmailActive: () => void;
}

const Floating: React.FC<IFloating> = ({ active, onEmailActive }) => {
  const { t, i18n } = useTranslation();
  return (
    <F.Box active={active}>
      <F.Title>
        {i18n.language !== 'en-US' && (
          <F.TextWrapper>
            <F.Text>{t('freeRegister_float_1')}</F.Text>
            <F.Text>{t('freeRegister_float_2')}</F.Text>
          </F.TextWrapper>
        )}
      </F.Title>
      <F.List>
        <F.Item onClick={onEmailActive}>
          <F.Icon src={MailIconMobile} />
          <F.Text>{t('mail')}</F.Text>
        </F.Item>
        <F.Item href="https://talk.naver.com/ct/w4p495?frm=pblog#nafullscreen" target="_blank">
          <F.Icon src={NaverIconMobile} />
          <F.Text>{t('naver')}</F.Text>
        </F.Item>
        <F.Item href="https://open.kakao.com/o/s1yNMose" target="_blank">
          <F.Icon src={KakaoIconMobile} />
          <F.Text>{t('kakao')}</F.Text>
        </F.Item>
      </F.List>
    </F.Box>
  );
};

export default Floating;
