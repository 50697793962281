import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { WebAdmin1, WebAdmin2, WebAdmin3, WebAdmin4 } from '../../../assets/web';
import { CenterBox, PageWrapper } from './style/common.style';

const T = {
  Wrapper: styled(PageWrapper)``,
  Box: styled(CenterBox)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Title: styled.div`
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  TitleText: styled.div`
    font-size: 20px;
    color: ${({ theme }) => `${theme.blue}`};
    margin-bottom: 20px;

    &.desc {
      color: #000;
      font-size: 36px;
      margin-bottom: 40px;
    }
  `,
};

const List = {
  W: styled.ul`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 200px;
  `,
  Item: styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    width: 200px;

    &:last-child {
      margin: 0;
    }
  `,
  Img: styled.img`
    object-fit: contain;
    margin-bottom: 24px;
  `,
  Title: styled.div`
    color: ${({ theme }) => `${theme.blue}`};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 24px;

    position: absolute;
    top: 100px;

    &.one {
      top: 90px;
    }
  `,
  Desc: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    line-height: 20px;
    font-size: 14px;
    white-space: nowrap;
  `,
  Text: styled.div``,
};

const Admin: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <T.Wrapper id="p6">
      <T.Box>
        <T.Title>
          <T.TitleText>{t('admin_title')}</T.TitleText>
          <T.TitleText className="desc">{t('admin_title_2')}</T.TitleText>
        </T.Title>
        <List.W>
          <List.Item>
            <List.Img src={WebAdmin1} />
            <List.Title className={i18n.language === 'en-US' ? 'one' : ''}>
              <List.Text>{t('admin_1_title')}</List.Text>
              <List.Text>{t('admin_1_title_2')}</List.Text>
            </List.Title>
            <List.Desc>
              <List.Text>{t('admin_1_1')}</List.Text>
              <List.Text>{t('admin_1_2')}</List.Text>
              <List.Text>{t('admin_1_3')}</List.Text>
            </List.Desc>
          </List.Item>
          <List.Item>
            <List.Img src={WebAdmin2} />
            <List.Title>
              <List.Text>{t('admin_2_title')}</List.Text>
            </List.Title>
            <List.Desc>
              <List.Text>{t('admin_2_1')}</List.Text>
              <List.Text>{t('admin_2_2')}</List.Text>
              <List.Text>{t('admin_2_3')}</List.Text>
              <List.Text>{t('admin_2_4')}</List.Text>
            </List.Desc>
          </List.Item>
          <List.Item>
            <List.Img src={WebAdmin3} />
            <List.Title>
              <List.Text>{t('admin_3_title')}</List.Text>
            </List.Title>
            <List.Desc>
              <List.Text>{t('admin_3_1')}</List.Text>
              <List.Text>{t('admin_3_2')}</List.Text>
            </List.Desc>
          </List.Item>
          <List.Item>
            <List.Img src={WebAdmin4} />
            <List.Title className="one">
              <List.Text>{t('admin_4_title')}</List.Text>
              <List.Text>{t('admin_4_title_2')}</List.Text>
            </List.Title>
            <List.Desc>
              <List.Text>{t('admin_4_1')}</List.Text>
              <List.Text>{t('admin_4_2')}</List.Text>
            </List.Desc>
          </List.Item>
        </List.W>
      </T.Box>
    </T.Wrapper>
  );
};

export default Admin;
