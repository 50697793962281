import { css } from 'styled-components';

export default css`
  @keyframes mobileHeaderIn {
    0% {
      height: 0px;
    }
    100% {
      height: 465px;
    }
  }

  @keyframes mobileHeaderOut {
    0% {
      height: 465px;
    }
    100% {
      height: 0px;
    }
  }
`;
