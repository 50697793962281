import { css } from 'styled-components';
import {
  ScreenLoading2,
  ScreenStudent2,
  ScreenStudent3,
  ScreenStudent4,
  ScreenStudent5,
} from '../../assets/image/screen';

export default css`
  @keyframes SevenScreen {
    0% {
      background-image: url(${ScreenLoading2});
    }
    15% {
      background-image: url(${ScreenLoading2});
    }
    20% {
      background-image: url(${ScreenStudent2});
    }
    35% {
      background-image: url(${ScreenStudent2});
    }
    40% {
      background-image: url(${ScreenStudent3});
    }
    55% {
      background-image: url(${ScreenStudent3});
    }
    60% {
      background-image: url(${ScreenStudent4});
    }
    75% {
      background-image: url(${ScreenStudent4});
    }
    80% {
      background-image: url(${ScreenStudent5});
    }
    95% {
      background-image: url(${ScreenStudent5});
    }
    100% {
      background-image: url(${ScreenLoading2});
    }
  }
`;
