import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import tranEn from './langs/english.json';
import tranKr from './langs/korean.json';

export const resources = {
  'en-US': { translation: tranEn },
  'ko-KR': { translation: tranKr },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'ko-KR',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
