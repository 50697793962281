import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  WebCommon1,
  WebCommon2,
  WebCommon3,
  WebCommon4,
  WebCommon6,
  WebCommonBack,
} from '../../../assets/web';
import { BackgroundImage, CenterBox, PageWrapper } from './style/common.style';

const T = {
  Wrapper: styled(PageWrapper)``,
  Img: styled(BackgroundImage)`
    background: url(${WebCommonBack});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
  `,
  Box: styled(CenterBox)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Title: styled.div`
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  TitleText: styled.div`
    font-size: 20px;
    color: ${({ theme }) => `${theme.green}`};
    margin-bottom: 20px;

    &.desc {
      color: #000;
      font-size: 36px;
      margin-bottom: 40px;
    }
  `,
};

const List = {
  W: styled.ul`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 200px;
  `,
  Item: styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    margin-right: 20px;

    &:last-child {
      margin: 0;
    }
  `,
  Img: styled.img`
    object-fit: contain;
    margin-bottom: 24px;
  `,
  Title: styled.div`
    color: ${({ theme }) => `${theme.green}`};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 24px;

    position: absolute;
    top: 100px;

    &.one {
      top: 90px;
    }
  `,
  Desc: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    line-height: 20px;
    font-size: 14px;
    white-space: nowrap;
  `,
  Text: styled.div``,
};

const Common: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <T.Wrapper>
      <T.Box>
        <T.Title>
          <T.TitleText>{t('common_title')}</T.TitleText>
          <T.TitleText className="desc">{t('common_title_2')}</T.TitleText>
        </T.Title>
        <List.W>
          <List.Item>
            <List.Img src={WebCommon1} />
            <List.Title className={i18n.language === 'en-US' ? 'one' : ''}>
              <List.Text>{t('common_1_title')}</List.Text>
              <List.Text>{t('common_1_title_2')}</List.Text>
            </List.Title>
            <List.Desc>
              <List.Text>{t('common_1_1')}</List.Text>
              <List.Text>{t('common_1_2')}</List.Text>
            </List.Desc>
          </List.Item>
          <List.Item>
            <List.Img src={WebCommon2} />
            <List.Title className={i18n.language === 'en-US' ? 'one' : ''}>
              <List.Text>{t('common_2_title')}</List.Text>
              <List.Text>{t('common_2_title_2')}</List.Text>
            </List.Title>
            <List.Desc>
              <List.Text>{t('common_2_1')}</List.Text>
              <List.Text>{t('common_2_2')}</List.Text>
            </List.Desc>
          </List.Item>
          <List.Item>
            <List.Img src={WebCommon3} />
            <List.Title className={i18n.language === 'en-US' ? 'one' : ''}>
              <List.Text>{t('common_3_title')}</List.Text>
              <List.Text>{t('common_3_title_2')}</List.Text>
            </List.Title>
            <List.Desc>
              <List.Text>{t('common_3_1')}</List.Text>
              <List.Text>{t('common_3_2')}</List.Text>
            </List.Desc>
          </List.Item>
          <List.Item>
            <List.Img src={WebCommon4} />
            <List.Title>
              <List.Text>{t('common_4_title')}</List.Text>
            </List.Title>
            <List.Desc>
              <List.Text>{t('common_4_1')}</List.Text>
              <List.Text>{t('common_4_2')}</List.Text>
            </List.Desc>
          </List.Item>
          <List.Item>
            <List.Img src={WebCommon6} />
            <List.Title>
              <List.Text>Q&A</List.Text>
            </List.Title>
            <List.Desc>
              <List.Text>{t('common_6_1')}</List.Text>
              <List.Text>{t('common_6_2')}</List.Text>
            </List.Desc>
          </List.Item>
        </List.W>
      </T.Box>
      <T.Img />
    </T.Wrapper>
  );
};

export default Common;
