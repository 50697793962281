import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  MobileSecondBack1,
  MobileSecondBack2,
  WebMockUp1,
  WebMockUp2,
  WebMockUp3,
  WebMockUp4,
} from '../../../assets/web';
import { BackgroundImage } from '../web/style/common.style';

const T = {
  Wrapper: styled.article`
    width: 100%;
  `,
  Inner: styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  `,
  Img: styled(BackgroundImage)`
    background: url(${MobileSecondBack1});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.second {
      background: url(${MobileSecondBack2});
    }
  `,
  TitleWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  `,
  Title: styled.div`
    font-size: 16px;
    line-height: 24px;

    &.green {
      color: ${({ theme }) => theme.green};
    }

    &.blue {
      color: ${({ theme }) => theme.blue};
    }
  `,
  ImgWrapper: styled.div`
    width: 100%;
    max-width: 420px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  `,
  ImgBox: styled.div`
    position: relative;
    width: 180px;
    height: 250px;
  `,
  MockUpImg: styled.img`
    position: absolute;
    width: 180px;
    height: auto;
    object-fit: contain;

    &.left {
      left: -10px;
    }

    &.parents {
      left: -30px;
    }
  `,
};

const MobileSecond: React.FC = () => {
  const { t } = useTranslation();

  return (
    <T.Wrapper id="m_p2">
      <T.Inner>
        <T.TitleWrapper className="user">
          <T.Title>{t('home_second_left_1')}</T.Title>
          <T.Title>{t('home_second_left_2')}</T.Title>
          <T.Title className="green">{t('home_second_left_3')}</T.Title>
        </T.TitleWrapper>
        <T.ImgWrapper>
          <T.ImgBox>
            <T.MockUpImg src={WebMockUp1} />
          </T.ImgBox>
          <T.ImgBox>
            <T.MockUpImg src={WebMockUp2} className="parents" />
          </T.ImgBox>
        </T.ImgWrapper>
        <T.Img />
      </T.Inner>
      <T.Inner>
        <T.TitleWrapper className="admin">
          <T.Title>{t('home_second_right_1')}</T.Title>
          <T.Title>{t('home_second_right_2')}</T.Title>
          <T.Title className="blue">{t('home_second_right_3')}</T.Title>
        </T.TitleWrapper>
        <T.ImgWrapper>
          <T.ImgBox>
            <T.MockUpImg src={WebMockUp3} className="left" />
          </T.ImgBox>
          <T.ImgBox>
            <T.MockUpImg src={WebMockUp4} className="web" />
          </T.ImgBox>
        </T.ImgWrapper>
        <T.Img className="second" />
      </T.Inner>
    </T.Wrapper>
  );
};

export default MobileSecond;
